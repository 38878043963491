import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./stylesModal.css";

export default function ModalDisabled({ cod, isOpen, onClose, user , showToast }){
    const [formData, setFormData] = useState({
        DNI: user?.DNI || '',
        APE_PAT: user?.APE_PAT || '',
        APE_MAT: user?.APE_MAT || '',
        NOMBRE: user?.NOMBRE || '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(cod=='disabled'){
            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/disabled-user', {
                    method: 'PUT',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                    body: JSON.stringify({DNI:formData.DNI})                
                });
                const result = await response.json();
    
                if (result['status'] === true) {
                    showToast('exito_disabled');
                    onClose();
                } else if (result['status'] === false) {
                    showToast('error');
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }else{
            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/enabled-user', {
                    method: 'PUT',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                    body: JSON.stringify({DNI:formData.DNI})                
                });
                const result = await response.json();
    
                if (result['status'] === true) {
                    showToast('exito_enabled');
                    onClose();
                } else if (result['status'] === false) {
                    showToast('error');
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };

    if (!isOpen) return null;
    return(
        
            <div className="modal-overlay">
            <div className="modal-content w-[70%]">
                <div className='px-2 border-b-4 border-primary-800'>
                    <h2 className='mb-2 text-xl font-semibold text-primary-800'>{cod=='disabled'?'Desactivar usuario':'Activar Usuario'}</h2>
                </div>
                <form className="w-full mx-auto" onSubmit={handleSubmit}>
                    <div className='grid grid-cols-3 gap-4 mt-4'>
                        <div>
                            <label htmlFor="DNI" className="block mb-2 text-sm font-medium text-primary-900">DNI</label>
                            <input type="number" id="DNI" value={formData.DNI} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="DNI" disabled />
                        </div>
                        <div>
                            <label htmlFor="APE_PAT" className="block mb-2 text-sm font-medium text-primary-900">APELLIDO PATERNO</label>
                            <input type="text" id="APE_PAT" value={formData.APE_PAT} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Apellido Paterno" disabled />
                        </div>
                        <div>
                            <label htmlFor="APE_MAT" className="block mb-2 text-sm font-medium text-primary-900">APELLIDO MATERNO</label>
                            <input type="text" id="APE_MAT" value={formData.APE_MAT} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Apellido Materno" disabled />
                        </div>
                    </div>
                    <div className='grid grid-cols-2 mt-4 mb-4 gap-8'>
                        <div>
                            <label htmlFor="NOMBRE" className="block mb-2 text-sm font-medium text-primary-900">NOMBRE</label>
                            <input type="text" id="NOMBRE" value={formData.NOMBRE} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Nombre" disabled />
                        </div>
                        <div>
                            <label htmlFor="DNI" className="block mb-2 text-sm font-medium text-primary-900">USUARIO</label>
                            <input type="number" value={formData.DNI} id="DNI" className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Usuario" disabled />
                        </div>
                    </div>
                    <div className='flex justify-end p-2 border-t-2 border-primary-800 gap-4'>
                        <button type="submit" 
                        className={`rounded border-2 p-2.5 
                            ${cod === 'disabled' ? 'bg-red-500 text-white border-red-500' : 'bg-green-500 text-white border-green-500 hover:bg-green-700 hover:text-white'}`}>
                        {cod=='disabled'?'Desactivar':'Activar'}</button>
                        <button type="button" onClick={onClose} className='bg-red-50 text-primary-800 rounded border-2 border-primary-800 p-2.5 ml-2 hover:bg-secondary-400 hover:text-primary-800'>Cerrar</button>
                    </div>
                </form>
            </div>
        </div>
        
    );
}