import React, { useEffect, useState }from "react";

export default function ModalAddButton({isOpen, onClose, user }){
    const [Error,setError]=useState('');
    const [btnExpediente,setBtnExpediente]=useState('');
    const [formData, setFormData] = useState({
        DNI: user?.DNI || '',
        APE_PAT: user?.APE_PAT || '',
        APE_MAT: user?.APE_MAT || '',
        NOMBRE: user?.NOMBRE || '',
        AGENCIA: user?.AGENCIA || '',
        DEPARTAMENTO: user?.DEPARTAMENTO || '',
        CARGO: user?.CARGO || '',
        FECHA: user?.FECHA || '',
        HORA: user?.HORA || '',
    });

    const handleEnableBtnUser = async(cod_btn,event)=>{
        event.preventDefault();
        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/enabled-btn', {
                method:'PUT',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify({DNI:user.DNI, cod_btn:cod_btn})
            });
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if(result.status==true){
                alert('Boton activado');
                fetchData();
            }else{
                alert('error');
            }
        } catch (error) {
            setError(error); // Actualizar el estado con el error si ocurre
        }
    }

    const handleDisabledBtnUser = async(cod_btn,event)=>{
        event.preventDefault();

        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/disabled-btn', {
                method:'PUT',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify({DNI:user.DNI, cod_btn:cod_btn})
            });
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if(result.status==true){
                alert('Boton desactivado');
                fetchData();
            }else{
                alert('error');
            }
        } catch (error) {
            setError(error); // Actualizar el estado con el error si ocurre
        }
    }

        const fetchData = async () => {
            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/btn-por-user', {
                    method:'POST',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                    body: JSON.stringify({DNI:user.DNI})
                });
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setBtnExpediente(result.expediente); // Actualizar el estado con los datos recibidos
            } catch (error) {
                setError(error); // Actualizar el estado con el error si ocurre
            }
        };

        useEffect(() => {
            if (isOpen) {
                fetchData();
            }
        }, [isOpen]);

        const handleClose = () => {
            onClose();
        };

    return(
        <div className="modal-overlay" onClick={handleClose}>
            <div className="modal-content w-[70%] max-h-[90vh] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                <div className='px-2 border-b-2 border-primary-800'>
                    <h2 className='mb-2 text-xl font-semibold text-primary-800'>INFORMACIÓN</h2>
                </div>
                <form className="w-full mx-auto" >
                    <div className="m-4">
                        <label htmlFor="title_" className="text-primary-800 font-bold p-2">DATOS COLABORADOR</label>
                        <div className="mx-2 border-2 border-primary-800 rounded-xl grid grid-cols-5">
                            <div className="m-2">
                                <label htmlFor="user" className="block mb-2 text-sm font-medium text-primary-900">NOMBRE Y APELLIDO</label>
                                <input disabled type="text" id="user" value={formData.DNI}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2024" required />
                            </div>
                            <div className="col-span-2 m-2">
                                <label htmlFor="nom_carp" className="block mb-2 text-sm font-medium text-primary-900">NOMBRE Y APELLIDO</label>
                                <input disabled type="text" id="nom_carp" value={`${formData.NOMBRE} ${formData.APE_PAT} ${formData.APE_MAT}`}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2024" required />
                            </div>
                            <div className="m-2">
                                <label htmlFor="agencia" className="block mb-2 text-sm font-medium text-primary-900">AGENCIA</label>
                                <input disabled type="text" id="agencia" value={formData.AGENCIA}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2024" required />
                            </div>
                            <div className="col-span-2 m-2">
                                <label htmlFor="departamente" className="block mb-2 text-sm font-medium text-primary-900">DEPARTAMENTO</label>
                                <input disabled type="text" id="departamente" value={formData.DEPARTAMENTO}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2024" required />
                            </div>
                            <div className="m-2">
                                <label htmlFor="cargo" className="block mb-2 text-sm font-medium text-primary-900">CARGO</label>
                                <input disabled type="text" id="cargo" value={formData.CARGO}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2024" required />
                            </div>
                            <div className="m-2">
                                <label htmlFor="fecha" className="block mb-2 text-sm font-medium text-primary-900">FECHA INGRESO</label>
                                <input disabled type="text" id="fecha" value={formData.FECHA}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2024" required />
                            </div>
                            <div className="m-2">
                                <label htmlFor="hora" className="block mb-2 text-sm font-medium text-primary-900">HORA DE INGRESO</label>
                                <input disabled type="text" id="hora" value={formData.HORA}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2024" required />
                            </div>
                        </div>
                    </div>
                    </form>
                    <div className="m-4 border-2 rounded-xl border-primary-800">
                        <label htmlFor="title_permisos" className="text-primary-800 font-bold p-2 ">MENÚ EXPEDIENTE</label>
                        <div className="mx-2 grid grid-cols-2">
                            <div className="border-2 border-primary-800 relative overflow-x-auto my-4 shadow-md sm:rounded-lg">
                                <table id='table_btn_user' className="w-full text-sm text-left rtl:text-right text-gray-500">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr className="bg-primary-800 text-primary-50">
                                            <th scope="col" className="p-4">
                                                CODIGO
                                            </th>
                                            <th scope="col" className="px-2 py-3">TITLE</th>
                                            <th scope="col" className="px-6 py-3">CONDICIÓN</th>
                                            <th scope="col" className="px-6 py-3">OPCIONES</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                    {btnExpediente && btnExpediente.map((btn)=>(

                                    
                                            <tr key={btn.cod_btn}  className="bg-white border-b text-primary-800 hover:bg-primary-100">
                                                
                                                <td className="px-4 py-2">{btn.cod_btn}</td>
                                                <td className="font-bold px-2 py-2">{btn.title}</td>
                                                <td className="px-2 py-2">{btn.isDisabled==true?<div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div> Habilitado</div>:<div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div> DESACTIVADO</div>}</td>
                                                <td className="px-2 py-2">{btn.isDisabled==true?
                                                    <button onClick={(e)=>handleDisabledBtnUser(btn.cod_btn,e)} className="tooltip">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z" fill="#EF0626"/>
                                                    </svg>
                                                    <span className="tooltiptextDisabled">Desactivar</span>
                                                </button>:
                                                <button onClick={(e)=>handleEnableBtnUser(btn.cod_btn,e)} className="tooltip">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.7071 10.7071C16.0976 10.3166 16.0976 9.68342 15.7071 9.29289C15.3166 8.90237 14.6834 8.90237 14.2929 9.29289L11 12.5858L9.20711 10.7929C8.81658 10.4024 8.18342 10.4024 7.79289 10.7929C7.40237 11.1834 7.40237 11.8166 7.79289 12.2071L10.2929 14.7071C10.6834 15.0976 11.3166 15.0976 11.7071 14.7071L15.7071 10.7071Z" fill="#20A61B"/>
                                                    </svg>
                                                    <span className="tooltiptextEdit">Activar</span>
                                                </button>}</td>               
                                            </tr>
                                ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-end p-2 border-t-2 border-primary-800 gap-4'>
                    <button type="submit" className='bg-primary-700 text-primary-50 rounded border-2 border-primary-800 p-2.5 hover:bg-primary-50 hover:text-primary-800'>Crear</button>
                    <button type="button" onClick={onClose}  className='bg-red-500 text-primary-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500'>Cerrar</button>
                    </div>
                
            </div>
        </div>
    );
}