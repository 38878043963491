import React , { useEffect, useState }from "react";
import { useNavigate  } from "react-router-dom";
import CardHome from "./common/card/CardHome";

export default function Home(){
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate(); // Inicializa el hook useNavigate

    useEffect(() => {
        // Recuperar los datos del usuario desde el almacenamiento local
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
        setUserData(JSON.parse(storedUserData));
        }else{
            navigate('/');
        }
    }, []);

    const handleSignOut = () => {
        // Elimina los datos del usuario del local storage
        localStorage.removeItem('userData');
        // Actualiza el estado de userData para reflejar que el usuario ha cerrado sesión
        setUserData(null);
        // Redirige al usuario a la página de inicio de sesión (o a cualquier otra página que prefieras)
        navigate('/'); // Asegúrate de tener una ruta /login en tu aplicación
    };

    const cards = [
            {
            id: 1,
            title: "CLUB FAMILIA DILE",
            image: `${process.env.PUBLIC_URL}/icons/icons_clubfamilia.svg`,
            link: "/club-familia/inicio",
            description: "Club de beneficios para socios de la Cooperativa DILE",
            requiredPermission: userData ? userData.club_familia[0].inicio : null
            },
            {
            id: 2,
            title: "GEO DILE",
            image: `${process.env.PUBLIC_URL}/icons/icons_geodile.svg`,
            link: "/geo-dile/inicio",
            description: "Sistema de geolocalización de socios de la Cooperativa DILE",
            requiredPermission: userData ? userData.geo_dile[0].inicio : null
            },
            {
            id: 3,
            title: "EXPEDIENTES",
            image: `${process.env.PUBLIC_URL}/icons/icons_expediente_logo.svg`,
            link: "/expedientes/inicio",
            description: "Sistema de control de expedientes de desembolsos de la Cooperativa DILE",
            requiredPermission: userData ? userData.expediente[0].inicio : null
            },
            {
            id: 4,
            title: "DASH-ADMIN",
            image: `${process.env.PUBLIC_URL}/icons/icons_cuotas.svg`,
            link: "/administrador/inicio",
            description: "Modulo administrativo APP-DILE",
            requiredPermission: userData ? userData.admin[0].inicio : null
            }
        ];

        return (
            <>
                {userData ? (
                    <>
                    <div className="w-full flex flex-col items-center justify-center bg-primary-800">
                        <div className="w-[90%] inline-flex rounded-xl my-4">
                            <div className="w-11/12  m-2">
                                <h2 className="text-primary-50 font-bold">
                                    BIENVENIDO{" "}
                                </h2>
                                <p className="text-primary-50 font-light mb-2 lg:text-lg smm:text-[12px]">
                                    {userData.NOMBRE} {userData.APE_PAT} {userData.APE_MAT}
                                </p>
                                <p className="text-primary-50 font-bold">
                                    USUARIO: <span className="font-light lg:text-lg smm:text-[12px]">{userData.DNI}</span>
                                </p>
                                <p className="text-primary-50 font-bold">
                                    CARGO: <span className="font-light lg:text-lg smm:text-[12px]">{userData.CARGO}</span>
                                </p>
                                <p className="text-primary-50 font-bold">
                                    AGENCIA: <span className="font-light lg:text-lg smm:text-[12px]">{userData.AGENCIA}</span>
                                </p>
                            </div>
                            <div className="w-1/12  m-4">
                            <button 
                                    onClick={handleSignOut} 
                                >
                                
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#fff" d="M35.5163 20C35.5163 11.4444 28.5556 4.48376 20 4.48376C11.4444 4.48376 4.48375 11.4444 4.48375 20C4.48375 28.5556 11.4444 35.5163 20 35.5163C28.5556 35.5163 35.5163 28.5556 35.5163 20ZM21.5231 19.9763C21.5231 20.7894 20.8644 21.4488 20.0506 21.4488C19.2375 21.4488 18.5781 20.7894 18.5781 19.9763V12.3181C18.5781 11.505 19.2375 10.8456 20.0506 10.8456C20.8638 10.8456 21.5231 11.5044 21.5231 12.3181V19.9763ZM30.0194 20.0256C30.0194 25.55 25.525 30.0444 20 30.0444C14.475 30.0444 9.98062 25.55 9.98062 20.0256C9.98062 17.3475 11.0237 14.83 12.9187 12.9381C13.2062 12.6506 13.5825 12.5075 13.9594 12.5075C14.3362 12.5075 14.7137 12.6513 15.0013 12.9394C15.5763 13.515 15.5756 14.4469 15 15.0219C13.6631 16.3581 12.9262 18.135 12.9262 20.0256C12.9262 23.9256 16.0994 27.0988 20 27.0988C23.9006 27.0988 27.0737 23.9256 27.0737 20.0256C27.0737 18.1363 26.3388 16.36 25.0025 15.0238C24.4275 14.4481 24.4281 13.5163 25.0031 12.9406C25.5788 12.365 26.5106 12.3663 27.0863 12.9413C28.9775 14.8338 30.0194 17.35 30.0194 20.0256Z" />
                                </svg>


                            </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center mt-4 px-6 mx-auto w-screen h-screen bg-primary-50">
                        <div className="lg:w-[80%] grid lg:grid-cols-4 smm:grid-cols-2 gap-6">
                            {cards.map((card) => (
                            card.requiredPermission=="activo" ? (
                                <CardHome
                                key={card.id}
                                title={card.title}
                                image={card.image}
                                link={card.link}
                                description={card.description}
                                output={false}
                                />
                            ) : (
                                ''
                            )
                            ))}
                        </div>
                    </div>
                    </>
                ) : (

                    <div className="w-full bg-primary-800">
                        <button 
                            onClick={handleSignOut} 
                        >
                                
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#fff" d="M35.5163 20C35.5163 11.4444 28.5556 4.48376 20 4.48376C11.4444 4.48376 4.48375 11.4444 4.48375 20C4.48375 28.5556 11.4444 35.5163 20 35.5163C28.5556 35.5163 35.5163 28.5556 35.5163 20ZM21.5231 19.9763C21.5231 20.7894 20.8644 21.4488 20.0506 21.4488C19.2375 21.4488 18.5781 20.7894 18.5781 19.9763V12.3181C18.5781 11.505 19.2375 10.8456 20.0506 10.8456C20.8638 10.8456 21.5231 11.5044 21.5231 12.3181V19.9763ZM30.0194 20.0256C30.0194 25.55 25.525 30.0444 20 30.0444C14.475 30.0444 9.98062 25.55 9.98062 20.0256C9.98062 17.3475 11.0237 14.83 12.9187 12.9381C13.2062 12.6506 13.5825 12.5075 13.9594 12.5075C14.3362 12.5075 14.7137 12.6513 15.0013 12.9394C15.5763 13.515 15.5756 14.4469 15 15.0219C13.6631 16.3581 12.9262 18.135 12.9262 20.0256C12.9262 23.9256 16.0994 27.0988 20 27.0988C23.9006 27.0988 27.0737 23.9256 27.0737 20.0256C27.0737 18.1363 26.3388 16.36 25.0025 15.0238C24.4275 14.4481 24.4281 13.5163 25.0031 12.9406C25.5788 12.365 26.5106 12.3663 27.0863 12.9413C28.9775 14.8338 30.0194 17.35 30.0194 20.0256Z" />
                        </svg>
                        </button>
                        <p>Volver a iniciar Sesión</p>
                    </div>
                    
                )}
            </>
            );
};