import React, { useEffect,useContext, useState } from "react";
import Navbar from "../navbar/navBar";
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import { UserContext } from '../UserContext.js';
import { Link } from "react-router-dom";
import Loader from "../common/Loader.jsx";
import ModaSinRegistros from "../common/Modal/ModalSinRegistros.jsx";
import Modal_ver_pdf from "../common/Modal/Modal_ver_pdf.jsx";


export default function MisSolicitudes_Analista(){
    const { userData, setUserData } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [Error, setError] = useState();
    const[solicitud,setSolicitud]=useState([]);
    const [urlFirm,setUrlFirm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isModalSinRegistroOpen, setIsModalSinRegistroOpen] = useState(false); // Estado para manejar la visibilidad del modal de error
    const [errorMessage, setErrorMessage] = useState(''); 

    const [modalIsOpenPDF, setModalIsOpenPDF] = useState(false);
    const [urlPdf,setUrlPdf]=useState(''); // Reemplaza con la URL de tu PDF

    const openModalPDF = async (url) => {
        try {
            const response= await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/firmar_expe',{
                method:'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify({ url:url,DNI:userData.DNI})
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setUrlPdf(result);
            setModalIsOpenPDF(true);
        } catch (error) {
            setError(error); // Actualizar el estado con el error si ocurre
        }

    };

    const handlecloseModalPDF = () => {
        setModalIsOpenPDF(false);
    };


    const fetchData = async () => {
        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/mis-solicitud', {
                method: 'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify({user:userData.DNI})
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if (!result.data || !Array.isArray(result.data)) {
                throw new Error('Invalid data format received');
            }
            setSolicitud(result.data);// Actualizar el estado con los datos recibidos
            setLoading(false);
        } catch (error) {
            setError(error); // Actualizar el estado con el error si ocurre
            setLoading(false);
            setIsModalSinRegistroOpen(true); // Mostrar el modal de error
            setErrorMessage('REGISTRO VACIO');
        }finally{
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleverSolicitud= async (enlace) =>{
        try {
            const response= await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/firmar_expe',{
                method:'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify({ url:enlace,DNI:userData.DNI})
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setUrlFirm(result);
            window.open(result);
        } catch (error) {
            setError(error); // Actualizar el estado con el error si ocurre
        }
    }


    return(<>
        {isLoading && <Loader />}
        <Navbar/>
        <SidebarExpedientes/>
        {!isLoading && (<>
        <div className="p-4 sm:ml-64">
            <h1 className="font-light text-primary-800 m-2 text-xl"><span className="font-bold">Mis Solicitudes</span></h1>
            <div className="p-4 border-2 grid grid-cols-1 gap-4 border-gray-200 border-dashed rounded-lg"></div>
                <div className="smm:hidden lg:flex lg:relative lg:overflow-x-auto lg:shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr className="bg-primary-800 text-primary-50">
                            <th scope="col" className="p-4">N°</th>
                            <th scope="col" className="px-2 py-3">CODIGO</th>
                            <th scope="col" className="px-6 py-3">FECHA INGRESO</th>
                            <th scope="col" className="px-6 py-3">FECHA RESPUESTA</th>
                            <th scope="col" className="px-6 py-3">CONDICIÓN</th>
                            <th scope="col" className="px-6 py-3">OPCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                        {solicitud.map((solicitud) => (
                            <tr key={solicitud.num} className="bg-white border-b hover:bg-primary-100">
                                <td className="w-4 p-4">{solicitud.num}</td>
                                <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap">{solicitud.name}</th>
                                <td className="px-2 py-4">{solicitud.fecha_in}</td>
                                <td className="px-2 py-4">{solicitud.fecha_revisa}</td>
                                <td className="px-2 py-4">{solicitud.condicion}</td>
                                <td className="px-2 py-4">
                                    <div className="grid grid-cols-4"> 
                                        <button onClick={() => handleverSolicitud(solicitud.pdf_link)} className=" w-[25px]">
                                            <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.9501 16.2167C6.08343 16.2167 2.2001 13.3333 0.350098 8.98333C2.21676 4.63333 6.1001 1.75 10.9501 1.75C15.8001 1.75 19.7001 4.63333 21.5668 8.98333C19.7001 13.3333 15.8001 16.2167 10.9501 16.2167ZM10.9501 14.4833C14.8001 14.4833 18.1168 12.2333 19.6668 8.98333C18.1168 5.73333 14.8001 3.48333 10.9501 3.48333C7.11676 3.48333 3.8001 5.73333 2.2501 8.98333C3.78343 12.2333 7.1001 14.4833 10.9501 14.4833ZM6.91676 8.98333C6.91676 6.75 8.71676 4.95 10.9334 4.95C13.1668 4.95 14.9668 6.76667 14.9668 8.98333C14.9668 11.2167 13.1501 13.0167 10.9334 13.0167C8.71676 13.0167 6.91676 11.2 6.91676 8.98333ZM11.1834 7.96667C11.1834 8.66667 11.7334 9.23333 12.4501 9.23333C13.1334 9.23333 13.7001 8.66667 13.7001 7.96667C13.7001 7.28333 13.1501 6.71667 12.4501 6.71667C11.7501 6.71667 11.1834 7.26667 11.1834 7.96667Z" fill="#02518C"/>
                                            </svg>
                                        </button>
                                    </div>    
                                </td>                                   
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                {/**VISTA DESDE UN CELULAR CAMBIO LA TABLA POR CARD-EXPLORATION */}
                {solicitud.map((solicitud) => (
                        <div key={solicitud.num} className="lg:hidden bg-primary-50 border-2 border-primary-800 rounded-lg shadow-lg shadow-primary-800">
                            <div className="p-2 text-primary-800 flex">
                                <div className="w-2/12 flex justify-center items-center content-center">
                                    <button onClick={() => openModalPDF(solicitud.pdf_link)}
                                    className="w-[90%] ">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/icons/icons_solicitud_menu.svg`}
                                        loading="lazy"
                                        className="w-full h-full"
                                        />
                                    </button>
                                </div>
                                <div className="w-7/12 justify-center items-center">
                                    <p className="text-[12px] font-bold">CODIGO SOLICITUD: <span className="font-light"> {solicitud.name} </span></p>
                                    <p className="text-[12px] font-bold">FECHA INGRESO: <span className="font-light">{solicitud.fecha_in}</span></p>
                                    <p className="text-[12px] font-bold">FECHA REVISIÓN: <span className="font-light">{solicitud.fecha_revisa}</span></p>                                
                                </div>
                                <div className="w-3/12 flex justify-center">
                                {solicitud.condicion==='Pendiente'?
                                <div className="flex items-center">
                                    <div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2">
                                    </div> 
                                    Pendiente
                                </div>:(solicitud.condicion==='Aprobado'?
                                    <div className="flex items-center">
                                    <div className="h-2.5 w-2.5 rounded-full bg-secondary-500 me-2">
                                    </div> 
                                    Aprobado
                                </div>:
                                <div className="flex items-center">
                                    <div className="h-2.5 w-2.5 rounded-full bg-green-700 me-2">
                                    </div> 
                                    Revisado
                                </div>
                                )
                                }                                    
                                        
                                </div>
                            </div>
                        </div>
                    ))}
        </div>
        <ModaSinRegistros
        isOpen={isModalSinRegistroOpen} 
        onClose={() => setIsModalSinRegistroOpen(false)} 
        message={errorMessage} 
        />
        <Modal_ver_pdf
                isOpen={modalIsOpenPDF}
                onClose={handlecloseModalPDF}
                url={urlPdf}
                />
        </>)}
        </>
    );
}