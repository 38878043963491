import React from "react";
import { Link } from 'react-router-dom';
import "./styles_link_button.css";

export default function Botton_MenuPrincipal({title,icons,link,isDisabled}){
    return(
        <>
        <li>
            <Link
                to={isDisabled ?link  : '#'}
                className={`flex items-center pl-2 border-primary-800 border-2 bg-primary-50 group rounded-lg ${
                isDisabled ? '' : 'disabled-link'
                }`}
            >
                <img
                src={`${process.env.PUBLIC_URL}${icons}`}
                loading="lazy"
                className="w-6 h-6"
                />
                <div className='w-full ml-2 p-2 rounded-r-lg group bg-primary-800 hover:bg-secondary-400'>
                <span className="w-full text-[12px] text-primary-50">{title}</span>
                </div>
            </Link>
        </li>
        </>
    );
}