import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./stylesModal.css";

export default function ModalCambioClave({ isOpen, onClose, user }){
    const [formData, setFormData] = useState({
        pass_ac:'',
        pass_new:'',
        repit_pass_new:'',
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value // No convertir a mayúsculas
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.pass_new === formData.repit_pass_new){
            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/change-pass', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({user: user.DNI, pass: formData.pass_ac, newpass: formData.pass_new})                
                });
                const result = await response.json();
                console.log(result);
                toast.success('Cambio de clave exitoso');
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error al cambiar la clave');
            }
        } else {
            toast.error('Las claves no coinciden');
        }
    };

    if (!isOpen) return null;
    return(
        <div className="modal-overlay">
            <div className="modal-content w-[70%]">
                <div className='px-2 border-b-4 border-primary-800'>
                    <h2 className='mb-2 text-xl font-semibold text-primary-800'>Cambio de clave</h2>
                </div>
                <form className="w-full mx-auto" onSubmit={handleSubmit}>
                    <div className='grid grid-cols-3 gap-4 mt-4'>
                        <div>
                            <label htmlFor="DNI" className="block mb-2 text-sm font-medium text-primary-900">DNI</label>
                            <input type="number" id="DNI" value={user.DNI} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="DNI" disabled />
                        </div>
                        <div>
                            <label htmlFor="APE_PAT" className="block mb-2 text-sm font-medium text-primary-900">APELLIDO PATERNO</label>
                            <input type="text" id="APE_PAT" value={user.APE_PAT} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Apellido Paterno" disabled />
                        </div>
                        <div>
                            <label htmlFor="APE_MAT" className="block mb-2 text-sm font-medium text-primary-900">APELLIDO MATERNO</label>
                            <input type="text" id="APE_MAT" value={user.APE_MAT} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Apellido Materno" disabled />
                        </div>
                    </div>
                    <div className='grid grid-cols-2 mt-4 mb-4 gap-8'>
                        <div>
                            <label htmlFor="NOMBRE" className="block mb-2 text-sm font-medium text-primary-900">NOMBRE</label>
                            <input type="text" id="NOMBRE" value={user.NOMBRE} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Nombre" disabled />
                        </div>
                        <div>
                            <label htmlFor="USUARIO" className="block mb-2 text-sm font-medium text-primary-900">USUARIO</label>
                            <input type="text" value={user.DNI} id="USUARIO" className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Usuario" disabled />
                        </div>
                    </div>
                    <div className='grid grid-cols-3 gap-4 mb-2'>
                        <div>
                            <label htmlFor="pass_ac" className="block mb-2 text-sm font-medium text-primary-900">CLAVE ACTUAL</label>
                            <input type="password" 
                            value={formData.pass_ac} 
                            onChange={handleChange}
                            id="pass_ac" 
                            required
                            className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Clave actual" />
                        </div>
                        <div>
                            <label htmlFor="pass_new" className="block mb-2 text-sm font-medium text-primary-900">CLAVE NUEVA</label>
                            <input type="password" 
                            value={formData.pass_new} 
                            onChange={handleChange}
                            id="pass_new" 
                            required
                            className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Clave nueva" />
                        </div>
                        <div>
                            <label htmlFor="repit_pass_new" className="block mb-2 text-sm font-medium text-primary-900">REPETIR CLAVE NUEVA</label>
                            <input type="password" 
                            value={formData.repit_pass_new} 
                            onChange={handleChange}
                            id="repit_pass_new" 
                            required
                            className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Repita clave nueva" />
                        </div>
                    </div>
                    <div className='flex justify-end p-2 border-t-2 border-primary-800 gap-4'>
                        <button type="submit" className='bg-primary-800 text-primary-50 rounded border-2 border-primary-800 p-2.5 hover:bg-primary-50 hover:text-primary-800'>Procesar</button>
                        <button type="button" onClick={onClose} className='bg-red-500 text-red-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500'>Cancelar</button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
}
