import React, { useCallback, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { UserContext } from '../UserContext.js';
import { Link, useNavigate } from 'react-router-dom';
import ModalCambioClave from "../common/Modal/ModalCambioClave.jsx";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

// Navegación (puede ser dinámico según necesidades)
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
];

// Función para combinar clases condicionalmente
const classNames = (...classes) => classes.filter(Boolean).join(' ');

const Navbar = () => {
  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate(); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  // Función para abrir el modal y setear el usuario
  const handleModalOpen = useCallback((user) => {
    setIsModalOpen(true);
    setUserInfo(user);
  }, []);

  // Función para cerrar el modal
  const handleModalClose = useCallback(() => setIsModalOpen(false), []);

  // Función para cerrar sesión
  const handleSignOut = useCallback(() => {
    localStorage.removeItem('userData');
    setUserData(null);
    navigate('/');
  }, [navigate, setUserData]);

  // Temporizador de inactividad (15 minutos)
  useEffect(() => {
    const timeLimit = 15 * 60 * 1000; 
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(handleSignOut, timeLimit);
    };

    const events = ['mousemove', 'keydown', 'click'];
    events.forEach(event => window.addEventListener(event, resetTimer));
    timer = setTimeout(handleSignOut, timeLimit);

    return () => {
      clearTimeout(timer);
      events.forEach(event => window.removeEventListener(event, resetTimer));
    };
  }, [handleSignOut]);

  return (
    <Disclosure as="nav" className="bg-primary-50 shadow-lg">
      {({ open }) => (
        <>
          <div className="flex w-full lg:px-8 smm:pl-3">
            <Link to={'/home'} className="flex w-full mt-3 justify-self-start">
              <img 
                className="lg:w-32 lg:h-10 smm:w-20 smm:h-8 md:w-20" 
                src={`${process.env.PUBLIC_URL}/images/logo1.png`} 
                alt="Logo" 
              />
            </Link>
            <div className="relative flex w-[800px] h-16 items-center justify-end">
              <div className="relative inset-y-0 right-0 flex items-center pr-2">
                {userData ? (
                  <div className="hidden lg:block mr-2 text-primary-800 items-center text-center border-r-2 border-primary-50">
                    <div className="p-4">
                      <p className="text-[12px] font-light">{`${userData.NOMBRE} ${userData.APE_PAT} ${userData.APE_MAT}`}</p>
                      <p className="text-[10px] font-bold">{userData.AGENCIA}</p>
                    </div>
                  </div>
                ) : (
                  <div className="hidden lg:block mr-2 text-primary-800 items-center text-center border-r-2 border-primary-50">
                    <div className="p-4">
                      <p className="text-[12px] font-light">Cargando...</p>
                    </div>
                  </div>
                )}

                <Menu as="div" className="relative ml-3">
                  <Menu.Button className="relative flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-primary-50" aria-label="User profile">
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/icons_profile_user.svg`}
                      loading="lazy"
                      className="w-10 h-10"
                      alt="Profile"
                    />
                  </Menu.Button>
                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="lg:hidden border-b-2 border-primary-800">
                        <div className="p-4">
                          <p className="text-[12px] font-light">{`${userData?.NOMBRE} ${userData?.APE_PAT} ${userData?.APE_MAT}`}</p>
                          <p className="text-[10px] font-bold">{userData?.AGENCIA}</p>
                        </div>
                      </div>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(active ? 'bg-primary-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                          >
                            Mi perfil
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => handleModalOpen(userData)}
                            className={classNames(active ? 'bg-primary-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                          >
                            Cambio de clave
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleSignOut}
                            className={classNames(active ? 'bg-primary-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                          >
                            Cerrar sesión
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map(item => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>

          <ModalCambioClave
            isOpen={isModalOpen}
            onClose={handleModalClose}
            user={userInfo}
          />
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
