import React, { useState, useEffect } from "react";

export default function CardExpediente({ icons, title, num }) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (num !== undefined && num !== null && num !== "") {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [num]);

    if (isLoading) {
        return (
            <div className="w-[80%] flex justify-center items-center bg-primary-50 border-4 border-primary-800 rounded-lg">
                <div className="text-center p-4">
                    <span className="text-primary-800 font-bold text-[24px]">Cargando...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="w-[80%] mb-4 flex-row content-center justify-center items-center bg-primary-50 border-4 border-primary-800 rounded-lg">
            <div className="text-center">
                <span className="text-primary-800 font-bold text-[24px]">{title}</span>
            </div>
            <div className="grid grid-cols-2 p-2">
                <div className="flex justify-center items-center">
                    <img
                        src={`${process.env.PUBLIC_URL}${icons}`}
                        alt={`icon-chanchito ${title}`}
                        loading="lazy"
                        className="w-24 h-24"
                    />
                </div>
                <div className="flex justify-center items-center">
                    <div className="text-center">
                        <span className="text-primary-800 font-bold text-[70px]">{num==0?'0':num}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
