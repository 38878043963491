import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Botton_MenuPrincipal from "../common/bottonMenu/botton_MenuPrincipal.jsx";

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <>
      <button
        data-drawer-target="sidebar-multi-level-sidebar"
        data-drawer-toggle="sidebar-multi-level-sidebar"
        aria-controls="sidebar-multi-level-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-primary-800 rounded-lg sm:hidden hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </button>

        <aside
            id="sidebar-multi-level-sidebar"
            className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } sm:translate-x-0`}
            aria-label="Sidebar"
          >
            {/* Sidebar content */}

            <div className="h-full px-8 py-4 overflow-y-auto bg-secondary-200 shadow-lg">
                <div className='w-full flex justify-center items-center mb-16'>
                    <img className='w-32' src="../images/logo1.png"/>
                </div>
                <ul className="space-y-2 font-medium">
                    <li>
                        <a href="#" className="flex items-center pl-2 border-primary-800 border-2 bg-primary-50 group rounded-lg">
                            <svg className="w-6 h-6 text-primary-800 transition duration-75 group-hover:text-primary-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                                <path d="M16.975 8H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
                                <path d="M12.5 0c-.157 0-.38.01-.565.027A1 1 0 0 0 8 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
                            </svg>
                          <div className='w-full ml-2 p-2 rounded-r-lg group bg-primary-800 hover:bg-secondary-400 hover:border-2 hover:border-l-primary-800'>
                            <span className=" w-full text-primary-50">Dashboard</span>
                          </div>
                        </a>
                    </li>
                    <li>
                    <button
                      type="button"
                      className="flex items-center w-full p-2 text-base text-primary-800 transition duration-75 rounded-lg group hover:bg-secondary-400"
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                <svg className="flex-shrink-0 w-5 h-5 text-primary-800 transition duration-75 group-hover:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                </svg>
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  Usuarios
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
                <ul
                    id="dropdown-example"
                    className={`${isDropdownOpen ? 'block' : 'hidden'} py-1 space-y-0`}
                >
                    <li>
                    <Link
                        to={"/administrador/usuario"}
                        className="flex items-center w-full p-2 text-primary-800 transition duration-75 rounded-lg pl-8 group hover:bg-secondary-400"
                    >
                        Analistas
                    </Link>
                    </li>
                    <li>
                      <Link
                          to={"/administrador/usuarios-operaciones"}
                          className="flex items-center w-full p-2 text-primary-800 transition duration-75 rounded-lg pl-8 group hover:bg-secondary-400"
                      >
                          Operaciones
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/administrador/usuarios-recuperaciones"}
                          className="flex items-center w-full p-2 text-primary-800 transition duration-75 rounded-lg pl-8 group hover:bg-secondary-400"
                      >
                          Recuperaciones
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/administrador/usuarios-administrativos"}
                          className="flex items-center w-full p-2 text-primary-800 transition duration-75 rounded-lg pl-8 group hover:bg-secondary-400"
                      >
                          Administrativos
                      </Link>
                    </li>
                </ul>
                    </li>
                    <li>
                        <a href="/administrador/usuario-sd" className="flex items-center pl-2 border-primary-800 border-2 bg-primary-50 group rounded-lg">
                          <svg className="flex-shrink-0 w-5 h-5 text-primary-800 transition duration-75 group-hover:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                            <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                          </svg>
                          <div className='w-full ml-2 p-2 rounded-r-lg group bg-primary-800 hover:bg-secondary-400 hover:border-2 hover:border-l-primary-800'>
                            <span className=" w-full text-primary-50">Usuarios</span>
                          </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="flex items-center p-2 text-primary-800 rounded-lg hover:bg-secondary-400 group">
                        <svg className="flex-shrink-0 w-5 h-5 text-primary-800 transition duration-75 group-hover:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span className="flex-1 ms-3 whitespace-nowrap">Inbox</span>
                        <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="flex items-center p-2 text-primary-800 rounded-lg hover:bg-secondary-400 group">
                        <svg className='text-primary-800' width="20" height="14" viewBox="0 0 20 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.89543 0.895431 0 2 0H18C19.1046 0 20 0.89543 20 2V12C20 13.1046 19.1046 14 18 14H2C0.895431 14 0 13.1046 0 12V2ZM5.01 3H3V5.01H5.01V3ZM8.01 3H6V5.01H8.01V3ZM8.01 3H9V5.01H8.01V3ZM14.01 3H12V5.01H14.01V3ZM17.01 3H15V5.01H17.01V3ZM5.01 6H3V8.01H5.01V6ZM8.01 6H6V8.01H8.01V6ZM8.01 6H9V8.01H8.01V6ZM14.01 6H12V8.01H14.01V6ZM17.01 6H15V8.01H17.01V6ZM5.01 9H3V8.01H5.01V9ZM6 9L5.99857 8L14.01 8.01V9H6ZM17.01 9H15V8.01H17.01V9Z"/>
                        </svg>
                        <span className="flex-1 ms-3 whitespace-nowrap">Calculadora</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="flex items-center p-2 text-primary-800 rounded-lg hover:bg-secondary-400 group">
                        <svg className="flex-shrink-0 w-5 h-5 text-primary-800 transition duration-75 group-hover:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
                        </svg>
                        <span className="flex-1 ms-3 whitespace-nowrap">Products</span>
                        </a>
                    </li>
                    <li>
                        <a href="/home" className="flex items-center p-2 text-primary-800 rounded-lg hover:bg-secondary-400 group">
                        <svg className="flex-shrink-0 w-5 h-5 text-primary-800 transition duration-75 group-hover:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8h8m0 0L8 4m4 4-4 4m4-8h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"/>
                        </svg>
                        <span className="flex-1 ms-3 whitespace-nowrap">Sign In</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="flex items-center p-2 text-primary-800 rounded-lg hover:bg-secondary-400 group">
                        <svg className="flex-shrink-0 w-5 h-5 text-primary-800 transition duration-75 group-hover:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                            <path d="M6.737 8.061a2.961 2.961 0 0 1 .81-1.515l6.87-6.86A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v8a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                            <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.88-6.87a2.884 2.884 0 0 0-4.079-4.078l-6.87 6.87a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.98 5.98 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                        </svg>
                        <span className="flex-1 ms-3 whitespace-nowrap">Sign Up</span>
                        </a>
                    </li>
                </ul>
            </div>





        </aside>

        <div className="p-4 sm:ml-64">
            {/* Main content */}
        </div>
        </>
    );
}

export default Sidebar;
