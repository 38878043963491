import React, { useEffect, useState , useContext } from "react";
import Navbar from "../navbar/navBar";
import { Link, useParams } from 'react-router-dom';
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import CardExpediente_carpetas from "../common/card/CardExpediente_carpetas";
import { UserContext } from '../UserContext.js';
import ModalAddAnio from "../common/Modal/ModalAddAnio.jsx";
import { ToastContainer, toast } from 'react-toastify';
import ModaSinRegistros from "../common/Modal/ModalSinRegistros.jsx";
import Loader from "../common/Loader.jsx";

export default function RevisarExpedientes_A_anio() {
    const { agencia,asesor } = useParams();
    const [error, setError] = useState();
    const [data, setData] = useState([]);
    const {userData, setUserData } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSinRegistroOpen, setIsModalSinRegistroOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); 
    const [toastMessage, setToastMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    

    const handleAddCarp = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

        const fetchData = async () => {
            if (!userData) return;

            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/mis-carpetas-anio', {
                    method: 'POST',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                    body: JSON.stringify({ user:asesor })
                });
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                const result = await response.json();

                if(result.status==true){
                    setData(result.data); // Actualizar el estado con los datos recibidos

                }else{
                    setIsModalSinRegistroOpen(true); // Mostrar el modal de error
                    setErrorMessage('REGISTRO VACIO');
                }
                
            } catch (error) {
                setError(error);
            }finally{
                setIsLoading(false);
            }
        };
    
    
        useEffect(() => {
        fetchData();
    }, [userData]);

    const showToast = (message) => {
        setToastMessage(message);
        if(message=='existe'){
            toast.error('EL AÑO INGRESADO YA EXISTE.')
        }else{
            toast.success('CARPETA CREADA EXITOSAMENTE.');
            fetchData();
        }
        
    };

    return (
        <>
        {isLoading && <Loader />}
            <Navbar />
            <SidebarExpedientes />
            {!isLoading && (<>
            <div className="p-4 sm:ml-64">
                <h1 className="font-light text-primary-800 m-2 text-xl"><Link to="/expedientes/revisar_expediente">{"Revisar Expedientes > "}</Link><Link to={`/expedientes/revisar_expediente/${agencia}`}>{agencia}</Link>{' > '}<span className="font-bold">{asesor}</span></h1>
                <div className="p-4 border-2 grid grid-cols-1 gap-4 border-gray-200 border-dashed rounded-lg">
                    
                    <div className="lg:w-full smm:w-[50%] lg:grid lg:grid-cols-4 lg:gap-2 smm:grid-cols-1 smm:justify-center smm:items-center">
                    {data ? (
                        data.length > 0 ? (
                            data.map((item) => (
                                <>
                                    <CardExpediente_carpetas
                                        key={item.id}
                                        icons={'/icons/icons_year.svg'}
                                        link={`/expedientes/revisar_expediente/${agencia}/${asesor}/${item.nom_carp}`}
                                        title={item.nom_carp}
                                    />
                                </>
                            ))
                        ) : (
                            <div className="col-span-4 inline-flex mt-6" >
                            <div>
                                <img
                                    src={`${process.env.PUBLIC_URL}/icons/icons_advertencia.svg`}
                                    loading="lazy"
                                    className="w-6 h-6"
                                />
                            </div>
                            <div className="ml-2 font-bold text-orange-500">
                                <p>El usuario no a creado carpetas</p>
                            </div>
                        </div>
                        )
                    ) : (
                        <p>Cargando datos o no se encontró información.</p>
                    )}
                    </div>
                </div>
                <ModalAddAnio
                cod="A" 
                title={'AGREGAR AÑO'} 
                description={'(*) De forma predeterminado cargara el año actual, si desea puede editar el año'} 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                showToast={showToast}/>
            </div>
            <ToastContainer />
            <ModaSinRegistros
                isOpen={isModalSinRegistroOpen} 
                onClose={() => setIsModalSinRegistroOpen(false)} 
                message={errorMessage} 
                />
            </>
            )}
        </>
    );
}
