import React, { useEffect, useState , useContext }from "react";
import { UserContext } from '../../UserContext.js';
import { useParams } from 'react-router-dom';

export default function ModalAddAnio({cod,title,description,isOpen, onClose, user , showToast}){
    const [nom_carp,setNom_carp]=useState('');
    const { userData, setUserData } = useContext(UserContext);
    const { anio } = useParams();
    

     // Function to get the current year
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    const getCurrentMonth=()=>{
        const months = [
            "01 ENERO", "02 FEBRERO", "03 MARZO", "04 ABRIL", "05 MAYO", "06 JUNIO",
            "07 JULIO", "08 AGOSTO", "09 SEPTIEMBRE", "10 OCTUBRE", "11 NOVIEMBRE", "12 DICIEMBRE"
        ];
        return months[new Date().getMonth()];
    }

    useEffect(() => {
        if (isOpen) {
            if(cod=="A"){
                setNom_carp(String(getCurrentYear()));
            }else{
                setNom_carp(getCurrentMonth());
            }
        }
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(cod=="A"){
            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/add-carpeta-anio', {
                    method: 'POST',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                    body: JSON.stringify({user:userData.DNI, nom_carp:nom_carp,})                
                });
                const result = await response.json();
                if (result['status'] === false) {
                    showToast('existe');
                } else {
                    showToast('add_carp');
                    onClose();
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }else if (cod=="M"){
            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/add-carpeta-mes', {
                    method: 'POST',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                    body: JSON.stringify({user:userData.DNI, anio:anio, nom_carp:nom_carp,})                
                });
                const result = await response.json();
                console.log(result);
                if (result['status'] === false) {
                    showToast('existe');
                } else {
                    showToast('add_carp');
                    onClose();
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };


    if (!isOpen) return null;
    return(
        <div className="modal-overlay">
            <div className="modal-content w-[70%]">
                <div className='px-2 border-b-2 border-primary-800'>
                    <h2 className='mb-2 lg:text-xl smm:text-sm font-semibold text-primary-800'>{title}</h2>
                </div>
                <form className="w-full mx-auto" onSubmit={handleSubmit} >
                    <div className="mx-4"><p className="text-primary-800 text-[10px]">{description}</p></div>
                    <div className="m-4">
                        <label htmlFor="nom_carp" className="block mb-2 lg:text-sm smm:text-[12px] font-medium text-primary-800">{'NOMBRE DE CARPETA (*)'}</label>
                        <input type="text" id="nom_carp" 
                        value={nom_carp} 
                        onChange={(e) => setNom_carp(e.target.value)}
                        className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2024" required />
                    </div>

                    <div className='flex justify-end p-2 border-t-2 border-primary-800 gap-4'>
                    <button type="submit" className='bg-primary-700 text-primary-50 rounded border-2 border-primary-800 p-2.5 hover:bg-primary-50 hover:text-primary-800'>Crear</button>
                    <button type="button" onClick={onClose} className='bg-red-500 text-primary-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500'>Cerrar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}