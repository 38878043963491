import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import "./stylesModal.css";

export default function ModalAddUser({ cod, isOpen, onClose, user , showToast }) {
    const [agencias, setAgencias] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [cargos, setCargos] = useState([]);
    const [formData, setFormData] = useState({
        DNI:'',
        APE_PAT:'',
        APE_MAT:'',
        NOMBRE:'',
        PASS: '',
        KEY:'',
        SECRET_KEY:'',
        AGENCIA: '',
        DEPARTAMENTO: '',
        CARGO: '',
        CLUBFAMILIA: 'activo',
        EXPEDIENTES: 'activo',
        GEODILE: 'activo'
    });

    useEffect(() => {
        if (user) {
            setFormData({
                DNI: user.DNI || '',
                APE_PAT: user.APE_PAT || '',
                APE_MAT: user.APE_MAT || '',
                NOMBRE: user.NOMBRE || '',
                PASS: '',
                KEY:'',
                SECRET_KEY:'',
                AGENCIA: '',
                DEPARTAMENTO: '',
                CARGO: '',
                CLUBFAMILIA: 'activo',
                EXPEDIENTES: 'activo',
                GEODILE: 'activo'
            });
        }
    }, [user]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const agenciasResponse = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/list_agencias',{
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                });
                const agenciasData = await agenciasResponse.json();
                setAgencias(agenciasData);
                

                const departamentosResponse = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/list_departamentos',{
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                });
                const departamentosData = await departamentosResponse.json();
                setDepartamentos(departamentosData);

                const cargosResponse = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/list_cargo',{
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                });
                const cargosData = await cargosResponse.json();
                setCargos(cargosData);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target;
        const fieldId = id.replace(`${cod}-`, '');
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldId]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/create_user', {
                method: 'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify(formData)                
            });
            const result = await response.json();
            if (result[0]['message'] === 'existe') {
                showToast('existe');
            } else if (result[0]['message'] === 'user_add') {
                showToast('user_add');
                onClose();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content w-[70%]">
                <div className='px-2 border-b-4 border-primary-800'>
                    <h2 className='mb-2 text-xl font-semibold text-primary-800'>Agregar usuario</h2>
                </div>
                <form id={cod} className="w-full mx-auto" onSubmit={handleSubmit}>
                    <div className='grid grid-cols-4 gap-4 mt-4'>
                        <div>
                            <label htmlFor="DNI_l" className="block mb-2 text-sm font-medium text-primary-900">DNI</label>
                            <input type="number" id={`${cod}-DNI`} value={formData.DNI} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="DNI" disabled />
                        </div>
                        <div>
                            <label htmlFor="APE_PAT_l" className="block mb-2 text-sm font-medium text-primary-900">APELLIDO PATERNO</label>
                            <input type="text" id={`${cod}-APE_PAT`}  value={formData.APE_PAT} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="Apellido Paterno" disabled />
                        </div>
                        <div>
                            <label htmlFor="APE_MAT_l" className="block mb-2 text-sm font-medium text-primary-900">APELLIDO MATERNO</label>
                            <input type="text" id={`${cod}-APE_MAT`}  value={formData.APE_MAT} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="Apellido Materno" disabled />
                        </div>
                        <div>
                            <label htmlFor="NOMBRE_l" className="block mb-2 text-sm font-medium text-primary-900">NOMBRE</label>
                            <input type="text" id={`${cod}-NOMBRE`}  value={formData.NOMBRE} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="Nombre" disabled />
                        </div>
                    </div>
                    <div className='grid grid-cols-4 gap-4 mt-4'>
                        <div>
                            <label htmlFor="Usuario_l" className="block mb-2 text-sm font-medium text-primary-900">USUARIO</label>
                            <input type="number" id={`${cod}-Usuario`}  value={formData.DNI}   className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="Usuario" disabled />
                        </div>
                        <div>
                            <label htmlFor="PASS_l" className="block mb-2 text-sm font-medium text-primary-900">PASSWORD</label>
                            <input type="password" id={`${cod}-PASS`}  value={formData.PASS} onChange={handleChange} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="*********" required />
                        </div>
                        <div>
                            <label htmlFor="KEY_l" className="block mb-2 text-sm font-medium text-primary-900">KEY AWS</label>
                            <input type="text" id={`${cod}-KEY`}  value={formData.KEY} onChange={handleChange} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="KEY" required />
                        </div>
                        <div>
                            <label htmlFor="SECRET_KEY_l" className="block mb-2 text-sm font-medium text-primary-900">SECRET KEY AWS</label>
                            <input type="text" id={`${cod}-SECRET_KEY`}  value={formData.SECRET_KEY} onChange={handleChange} className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="SECRET KEY" required />
                        </div>
                    </div>
                    <div className='grid grid-cols-3 gap-4 mt-4'>
                        <div>
                            <label htmlFor="AGENCIA_l" className="block mb-2 text-sm font-medium text-primary-800 ">AGENCIA</label>
                            <select id={`${cod}-AGENCIA`}  value={formData.AGENCIA} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                <option>SELECCIÓN ...</option>
                                {agencias && agencias.map((agencia) => (
                                    <option key={agencia._id} value={agencia.NOMBRE}>{agencia.NUM_AGENCIA} {'-'} {agencia.NOMBRE}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="DEPARTAMENTO_l" className="block mb-2 text-sm font-medium text-primary-800 ">DEPARTAMENTO</label>
                            <select id={`${cod}-DEPARTAMENTO`}  value={formData.DEPARTAMENTO} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                <option>SELECCIÓN ...</option>
                                {departamentos.map((dpto) => (
                                    <option key={dpto._id} value={dpto.NOM_DPTO}>{dpto.COD_DPTO} {'-'} {dpto.NOM_DPTO}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="CARGO_l" className="block mb-2 text-sm font-medium text-primary-800 ">CARGO</label>
                            <select  id={`${cod}-CARGO`}  value={formData.CARGO} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                <option>SELECCIÓN ...</option>
                                {cargos.map((cargo) => (
                                    <option key={cargo._id} value={cargo.NOM_CARGO}>{cargo.NUM_CARGO} {'-'} {cargo.NOM_CARGO}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='grid grid-cols-3 gap-4 mt-4 mb-4'>
                        <div>
                            <label htmlFor="CLUBFAMILIA_l" className="block mb-2 text-sm font-medium text-primary-800 ">CLUB FAMILIA</label>
                            <select id={`${cod}-CLUBFAMILIA`} value={formData.CLUBFAMILIA} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                <option value="activado">ACTIVADO</option>
                                <option value="desactivado">DESACTIVADO</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="EXPEDIENTES_l" className="block mb-2 text-sm font-medium text-primary-800 ">EXPEDIENTES</label>
                            <select id={`${cod}-EXPEDIENTES`} value={formData.EXPEDIENTES} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                <option value="activado">ACTIVADO</option>
                                <option value="desactivado">DESACTIVADO</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="GEODILE_l" className="block mb-2 text-sm font-medium text-primary-800 ">GEODILE</label>
                            <select id={`${cod}-GEODILE`} value={formData.GEODILE} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                <option value="activado">ACTIVADO</option>
                                <option value="desactivado">DESACTIVADO</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex justify-end p-2 border-t-2 border-primary-800 gap-4'>
                    <button type="submit" className='bg-primary-700 text-primary-50 rounded border-2 border-primary-800 p-2.5 hover:bg-primary-50 hover:text-primary-800'>Procesar</button>
                    <button type="button" onClick={onClose} className='bg-red-500 text-primary-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500'>Cerrar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
