import React from 'react';
import { Link } from 'react-router-dom';

export default function Card({image,title,link,description}) {
    return (
        <div className="flex justify-center items-center max-w-sm bg-primary-50">
            <Link to={link} className='block justify-center items-center'>
                <img className="rounded-t-lg " src={image} alt="Blog" />
            </Link>
        </div>
    );
}
