import React from "react";
import Navbar from "../navbar/navBar";
import Sidebar from "../sideBar/sideBar";

export default function Inicio(){
    return(
        <>
        <Navbar/>
        <Sidebar/>
        </>
    );
}