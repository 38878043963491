import React from "react";
import { Link } from "react-router-dom";

export default function CardExpediente_carpetas({icons,link,title}){
    const titleClass = title.length > 20 ? 'text-[12px]' : 'text-[20px]';
    return(
        <>
        <Link to={link}>
            <div className="lg:w-[80%] lg:h-[200px] smm:w-[100%] smm:mb-2 smm:flex lg:flex-col lg:content-center lg:justify-center lg:items-center bg-primary-50 border-4 border-primary-800 rounded-lg ">
                <div className="lg:flex lg:justify-center p-2">
                    <div className="lg:flex smm:justify-center smm:items-center">
                        <img
                        src={`${process.env.PUBLIC_URL}${icons}`}
                        alt={`icon-${title}`}
                        loading="lazy"
                        className="lg:w-24 lg:h-24 smm:w-12 smm:h-12"
                        />
                    </div>
                </div>
                <div className="lg:text-center  lg:px-1 smm:flex smm:justify-center smm:items-center">
                    <span className={`text-primary-800 font-bold ${titleClass}`}>{title}</span>
                </div>
            </div>
        </Link>
        </>
    );
}