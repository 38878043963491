import React, { useState } from "react";


export default function ModalAddFormatoVisitaSocio({isOpen, onClose, expe}){
    const [options, setOptions] = useState({
        selectedOption: '',
        selectedOpHabido: '',
        selectedOpDireccion: '',
        selectedOpAntiguedad: '',
        selectedOpCondicionLocal: '',
        selectedOpEstadoLocal: '',
        selectedOpNumLocal: '',
        selectedOpCondiVivienda: '',
        selectedOpMetodoPago: '',
        selectedOpRecibeVisita: '',
        selectedOpTratoAsesor: '',
        selectedOpNotificacionCobranza: '',
        selectedOpRecibioCronograma: '',
        selectedOpAsesorPideComision: '',
        selectedOpInformacionVerificada: ''
    });

    const handleOptionChange = (event) => {
        const { name, value } = event.target;
        setOptions(prevOptions => ({
            ...prevOptions,
            [name]: value
        }));
    };

    const date = new Date();
    const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString(undefined, optionsDate);
    if (!isOpen) return null;
    return (
        
        <div  className="modal-overlay">
            <div className="modal-content w-[75%] max-h-[90vh] overflow-y-auto">
                <div className='px-2 border-b-4 border-primary-800'>
                    <h2 className='mb-2 text-xl font-semibold text-primary-800'>FORMATO DE REVISION Y VISITA A SOCIOS</h2>
                </div>
                <form className="w-full mx-auto">
                    <div className='grid grid-cols-1 gap-4 mt-4'>
                        <label htmlFor="Fecha" className="text-sm font-medium text-primary-800">{`FECHA: ${formattedDate}`}</label>
                        <div className="grid grid-cols-5 items-center gap-4">
                            <label htmlFor="" className="text-[10px] text-primary-800">AMERITA VISITA: </label>
                            <div className="inline-flex gap-4">
                                <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                    <input 
                                    className="my-1"
                                    
                                    type="radio" name="selectedOption"
                                    value="SI"
                                    checked={options.selectedOption === 'SI'}
                                    onChange={handleOptionChange}
                                    />
                                    SI
                                </label>
                                <br />
                                <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                    <input 
                                    className="my-1" type="radio" name="selectedOption"
                                    value="NO"
                                    checked={options.selectedOption === 'NO'}
                                    onChange={handleOptionChange}
                                    />
                                    NO
                                </label>
                            </div>
                            <label htmlFor="" className="text-right text-[10px] text-primary-800">MOTIVACIÓN: </label>
                            <input className="uppercase px-3 col-span-2 rounded-lg border-primary-800 border-2 py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" 
                            value={'VERIFICACIÓN POST CRÉDITO'} disabled
                            type="text" />
                        </div>
                        <div className="border-2 border-primary-800">
                            <div className="grid grid-cols-4 m-2 gap-2">
                                {/**LADO DERECHO */}
                                    <div className="mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-2 text-[10px] text-primary-800" htmlFor="">DNI / RUC: </label>
                                        <input className="px-3 col-span-3 border-2 border-primary-800 rounded-md py-1 text-primary-800 text-[12px] focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800" 
                                        value={expe.dni_socio} disabled
                                        type="text" />
                                    </div>
                                    <div className="col-span-2 mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-1 text-[10px] text-primary-800" htmlFor="">SOCIO TITULAR: </label>
                                        <input className="px-3 col-span-4 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" 
                                        value={expe.nom_ape} disabled
                                        type="text" />
                                    </div>
                                    <div className="mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-2 text-[10px] text-primary-800" htmlFor="">CODIGO SOCIO: </label>
                                        <input className="px-3 col-span-3 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" type="text" />
                                    </div>
                                    <div className="mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-2 text-[10px] text-primary-800" htmlFor="">AGENCIA: </label>
                                        <input className="px-3 col-span-3 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" type="text" />
                                    </div>
                                    
                                    <div className="mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-2 text-[10px] text-primary-800" htmlFor="">CONYUGE DEL SOCIO: </label>
                                        <input className="px-3 col-span-3 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" type="text" />
                                    </div>
                                    <div className="col-span-2 mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-1 text-[10px] text-primary-800" htmlFor="">DIRECCIÓN DOMICILIO: </label>
                                        <input className="px-3 col-span-4 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" type="text" />
                                    </div>
                                    
                                    <div className="mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-2 text-[10px] text-primary-800" htmlFor="">DESCRIPCIÓN DE ACTIVIDAD: </label>
                                        <input className="px-3 col-span-3 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" type="text" />
                                    </div>
                                    <div className="mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-2 text-[10px] text-primary-800" htmlFor="">NOMBRE AVAL: </label>
                                        <input className="px-3 col-span-3 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" type="text" />
                                    </div>
                                    <div className="mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-2 text-[10px] text-primary-800" htmlFor="">ZONA (DISTRITO): </label>
                                        <input className="px-3 col-span-3 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" type="text" />
                                    </div>
                                    <div className="mb-1 grid grid-cols-5 items-center">
                                        <label className="col-span-2 text-[10px] text-primary-800" htmlFor="">ASESOR FINANCIERO: </label>
                                        <input className="px-3 col-span-3 border-2 border-primary-800 rounded-md py-1  focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800 text-[12px] text-primary-800" type="text" />
                                    </div>
                                
                            </div>
                        </div>
                        {/**tabla de créditos por socio */}
                        <div className="border-2 border-primary-800">
                            <table id='table_btn_user' className="w-full text-left rounded-xl">
                                    <thead className=" rounded-xl ">
                                        <tr className="bg-primary-800 text-center text-primary-50 rounded-xl">
                                            <th scope="col" className="px-2 py-3 text-[10px]">PAGARÉ</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">TIPO DE PRODUCTO</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">FECHA DESEMBOLSO</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">MONEDA</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">MONTO DESEMBOLSO</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">N° CUOTAS</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">FRECUENCIA</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">SALDO PRESENTE</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">TEA</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">AVAL</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">RIESGO</th>
                                            <th scope="col" className="px-2 py-3 text-[10px]">DIAS DE ATRASO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr  className="bg-white border-b text-primary-800 hover:bg-primary-100">
                                                
                                                <td className="px-2 py-2 text-[10px]">01-000212-24</td>
                                                <td className="px-2 py-2 text-[10px]">PAGO DIARIO</td>
                                                <td className="px-2 py-2 text-[10px]">24-06-2024</td>
                                                <td className="px-2 py-2 text-[10px]">SOLES</td>
                                                <td className="px-2 py-2 text-[10px]">S/. 10000.00</td>
                                                <td className="px-2 py-2 text-[10px]">90</td>
                                                <td className="px-2 py-2 text-[10px]">DIARIO</td>
                                                <td className="px-2 py-2 text-[10px]">9994.00</td>    
                                                <td className="px-2 py-2 text-[10px]">7.4%</td>
                                                <td className="px-2 py-2 text-[10px]">NO TIENES</td>
                                                <td className="px-2 py-2 text-[10px]">NORMAL</td>
                                                <td className="px-2 py-2 text-[10px]">4</td>            
                                            </tr>                      
                                    </tbody>
                                </table>
                        </div>

                        {/**DATOS DEL NEGOIO */}
                        <div className="border-2 border-primary-800">
                            <label htmlFor="" className="ml-2 text-[10px] text-primary-800">DATOS DEL NEGOCIO: </label>
                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="text-[10px] text-primary-800">SE ENCONTRO AL SOCIO</label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpHabido" value="NO HABIDO" 
                                        checked={options.selectedOpHabido === 'NO HABIDO'}
                                        onChange={handleOptionChange}
                                        />
                                        SOCIO NO HABIDO
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpHabido" value="HABIDO" 
                                        checked={options.selectedOpHabido === 'HABIDO'}
                                        onChange={handleOptionChange}
                                        />
                                        SOCIO HABIDO
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpHabido" value="OTROS" 
                                        checked={options.selectedOpHabido === 'OTROS'}
                                        onChange={handleOptionChange}
                                        />
                                        OTROS
                                    </label>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="text-[10px] text-primary-800">DIRECCION ES CORRECTA: </label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpDireccion" value="SI" 
                                        checked={options.selectedOpDireccion === 'SI'}
                                        onChange={handleOptionChange}
                                        />
                                        SI
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpDireccion" value="NO" 
                                        checked={options.selectedOpDireccion === 'NO'}
                                        onChange={handleOptionChange}
                                        />
                                        NO
                                    </label>
                                    {options.selectedOpDireccion==='NO'?
                                    <div className="flex w-[60%]">
                                        <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                            DIRECCIÓN CORRECTA</label>
                                            <input type="text" className="w-[70%] rounded-md ml-2 border-2 border-primary-800" />
                                    </div>:''
                                    }
                                    
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="text-[10px] text-primary-800">ANTIGUEDAD DEL NEGOCIO: </label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpAntiguedad" value="MENOR A 6 MESES" 
                                        checked={options.selectedOpAntiguedad === 'MENOR A 6 MESES'}
                                        onChange={handleOptionChange}
                                        />
                                        {'< 6 MESES'}
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpAntiguedad" value="MENOR A 12 MESES" 
                                        checked={options.selectedOpAntiguedad === 'MENOR A 12 MESES'}
                                        onChange={handleOptionChange}
                                        />
                                        {'< 12 MESES'}
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpAntiguedad" value="MENOR A 18 MESES" 
                                        checked={options.selectedOpAntiguedad === 'MENOR A 18 MESES'}
                                        onChange={handleOptionChange}
                                        />
                                        {'< 18 MESES'}
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpAntiguedad" value="MENOR A 24 MESES" 
                                        checked={options.selectedOpAntiguedad === 'MENOR A 24 MESES'}
                                        onChange={handleOptionChange}
                                        />
                                        {'< 24 MESES'}
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpAntiguedad" value="MAYOR A 24 MESES" 
                                        checked={options.selectedOpAntiguedad === 'MAYOR A 24 MESES'}
                                        onChange={handleOptionChange}
                                        />
                                        {'MAYOR A 24 MESES'}
                                    </label>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 ml-8 my-2">
                                <div className="block justify-start items-start ">
                                    <label htmlFor="" className="text-[10px] text-primary-800">CONDICIÓN DEL LOCAL: </label>
                                    <div className="block justify-start items-start ml-2">
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpCondicionLocal" value="PROPIO" 
                                            checked={options.selectedOpCondicionLocal === 'PROPIO'}
                                            onChange={handleOptionChange}
                                            />
                                            {'PROPIO'}
                                        </label>
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpCondicionLocal" value="ALQUILADO" 
                                            checked={options.selectedOpCondicionLocal === 'ALQUILADO'}
                                            onChange={handleOptionChange}
                                            />
                                            {'ALQUILADO'}
                                        </label>
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpCondicionLocal" value="N/A" 
                                            checked={options.selectedOpCondicionLocal === 'N/A'}
                                            onChange={handleOptionChange}
                                            />
                                            {'N/A'}
                                        </label>
                                    </div>
                                </div>
                                <div className="">
                                    <label htmlFor="" className="text-[10px] text-primary-800">ESTADO DEL LOCAL: </label>
                                    <div className="block justify-start items-start ml-2">
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpEstadoLocal" value="BUENO" 
                                            checked={options.selectedOpEstadoLocal === 'BUENO'}
                                            onChange={handleOptionChange}
                                            />
                                            {'BUENO'}
                                        </label>
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpEstadoLocal" value="REGULAR" 
                                            checked={options.selectedOpEstadoLocal === 'REGULAR'}
                                            onChange={handleOptionChange}
                                            />
                                            {'REGULAR'}
                                        </label>
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpEstadoLocal" value="MALO" 
                                            checked={options.selectedOpEstadoLocal === 'MALO'}
                                            onChange={handleOptionChange}
                                            />
                                            {'MALO'}
                                        </label>
                                    </div>
                                </div>
                                <div className="">
                                    <label htmlFor="" className="text-[10px] text-primary-800">NUMERO DE LOCAL: </label>
                                    <div className="block justify-start items-start ml-2">
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpNumLocal" value="UNO" 
                                            checked={options.selectedOpNumLocal === 'UNO'}
                                            onChange={handleOptionChange}
                                            />
                                            {'UNO'}
                                        </label>
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpNumLocal" value="DOS" 
                                            checked={options.selectedOpNumLocal === 'DOS'}
                                            onChange={handleOptionChange}
                                            />
                                            {'DOS'}
                                        </label>
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpNumLocal" value="OTROS" 
                                            checked={options.selectedOpNumLocal === 'OTROS'}
                                            onChange={handleOptionChange}
                                            />
                                            {'OTROS'}
                                        </label>
                                    </div>
                                </div>
                                <div className="">
                                    <label htmlFor="" className="text-[10px] text-primary-800">CONDICIÓN DEL DOMICILIO: </label>
                                    <div className="block justify-start items-start ml-2">
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpCondiVivienda" value="PROPIO" 
                                            checked={options.selectedOpCondiVivienda === 'PROPIO'}
                                            onChange={handleOptionChange}
                                            />
                                            {'PROPIO'}
                                        </label>
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpCondiVivienda" value="ALQUILADO" 
                                            checked={options.selectedOpCondiVivienda === 'ALQUILADO'}
                                            onChange={handleOptionChange}
                                            />
                                            {'ALQUILADO'}
                                        </label>
                                        <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                            <input className="my-1" type="radio" name="selectedOpCondiVivienda" value="FAMILIAR" 
                                            checked={options.selectedOpCondiVivienda === 'FAMILIAR'}
                                            onChange={handleOptionChange}
                                            />
                                            {'FAMILIAR'}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-6">
                                <div className="m-2 grid grid-cols-7">
                                    <label htmlFor="" className="text-[10px] text-primary-800">GIRO DEL NEGOCIO</label>
                                    <input type="text" className="col-span-6 w-[70%] rounded-md ml-2 border-2 border-primary-800" />
                                </div>
                                <div className="m-2 grid grid-cols-7">
                                    <label htmlFor="" className="text-[10px] text-primary-800">SITUACIÓN DEL NEGOCIO</label>
                                    <input type="text" className="col-span-6 w-[70%] rounded-md ml-2 border-2 border-primary-800" />
                                </div>
                            </div>
                        </div>
                        {/**MEDIO DE PAGO DEL SOCIO */}
                        <div className="border-2 border-primary-800 mb-2">
                            <label htmlFor="" className="ml-2 text-[10px] text-primary-800">MEDIO DE PAGO QUE UTILIZA EL SOCIO</label>
                            <div className="block justify-start items-start ml-4">
                                <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                    <input className="my-1" type="radio" name="selectedOpMetodoPago" value="AGENCIAS DE LA COOPERATIVA" 
                                    checked={options.selectedOpMetodoPago === 'AGENCIAS DE LA COOPERATIVA'}
                                    onChange={handleOptionChange}/>
                                    {'AGENCIAS DE LA COOPERATIVA'}
                                </label>
                                <div className="w-full grid grid-cols-5 items-center">
                                    <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpMetodoPago" value="BILLETERA DIGITAL" 
                                        checked={options.selectedOpMetodoPago === 'BILLETERA DIGITAL'}
                                        onChange={handleOptionChange}/>
                                        {'BILLETERA DIGITAL'}
                                    </label>
                                    {options.selectedOpMetodoPago==='BILLETERA DIGITAL'?
                                    <div className="w-full flex col-span-4">
                                        <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                            INDICAR NOMBRE DEL TITULAR Y BILLETERA</label>
                                            <input type="text" className="w-[60%] rounded-md ml-2 border-2 border-primary-800" />
                                    </div>:''
                                    }
                                </div>
                                <div className="w-full grid grid-cols-5 items-center mb-2">
                                    <label className="flex items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpMetodoPago" value="PERSONAL DE LA COOPERATIVA" 
                                        checked={options.selectedOpMetodoPago === 'PERSONAL DE LA COOPERATIVA'}
                                        onChange={handleOptionChange}/>
                                        {'PERSONAL DE LA COOPERATIVA'}
                                    </label>
                                    {options.selectedOpMetodoPago==='PERSONAL DE LA COOPERATIVA'?
                                        <div className="w-full flex col-span-4">
                                            <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                                NOMBRE DEL COLABORADOR</label>
                                                <input type="text" className="w-[60%] rounded-md ml-2 border-2 border-primary-800" />
                                        </div>:''
                                    }
                                </div>
                                
                            </div>
                        </div>

                        {/**3.- COMENTARIOS DEL SOCIO ACERCA DEL ASESOR FINANCIERO */}
                        <div className="border-2 border-primary-800">
                            <label htmlFor="" className="ml-2 text-[10px] text-primary-800">COMENTARIOS DEL SOCIO ACERCA DEL ASESOR FINANCIERO</label>
                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="col-span-1 text-[10px] text-primary-800">¿RECIBE VISITAS PERIODICAS DEL ASESOR FINANCIERO?</label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpRecibeVisita" value="SI" 
                                        checked={options.selectedOpRecibeVisita === 'SI'}
                                        onChange={handleOptionChange}
                                        />
                                        SI
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpRecibeVisita" value="NO" 
                                        checked={options.selectedOpRecibeVisita === 'NO'}
                                        onChange={handleOptionChange}
                                        />
                                        NO
                                    </label>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="col-span-1 text-[10px] text-primary-800">¿COMO ES EL TRATO DEL ASESOR FINANCIERO?</label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpTratoAsesor" value="BUENO" 
                                        checked={options.selectedOpTratoAsesor === 'BUENO'}
                                        onChange={handleOptionChange}
                                        />
                                        BUENO
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpTratoAsesor" value="MALO" 
                                        checked={options.selectedOpTratoAsesor === 'MALO'}
                                        onChange={handleOptionChange}
                                        />
                                        MALO
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/**3.- COMENTARIOS DEL SOCIO ACERCA DEL ASESOR FINANCIERO */}
                        <div className="border-2 border-primary-800">
                            <label htmlFor="" className="ml-2 text-[10px] text-primary-800">COMENTARIOS DE LA GESTION DE RECUPERACIÓN</label>
                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="col-span-1 text-[10px] text-primary-800">¿CUENTA CON NOTIFICACIÓNES DE COBRANZA?</label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpNotificacionCobranza" value="SI" 
                                        checked={options.selectedOpNotificacionCobranza === 'SI'}
                                        onChange={handleOptionChange}
                                        />
                                        SI
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpNotificacionCobranza" value="NO" 
                                        checked={options.selectedOpNotificacionCobranza === 'NO'}
                                        onChange={handleOptionChange}
                                        />
                                        NO
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/**COMENTARIOS DEL SOCIO */}
                        <div className="border-2 border-primary-800">
                            <label htmlFor="" className="ml-2 text-[10px] text-primary-800">COMENTARIOS DEL SOCIO</label>
                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="col-span-1 text-[10px] text-primary-800">¿RECIBIO CRONOGRAMA DE PAGO?</label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpRecibioCronograma" value="SI" 
                                        checked={options.selectedOpRecibioCronograma === 'SI'}
                                        onChange={handleOptionChange}
                                        />
                                        SI
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpRecibioCronograma" value="NO" 
                                        checked={options.selectedOpRecibioCronograma === 'NO'}
                                        onChange={handleOptionChange}
                                        />
                                        NO
                                    </label>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="col-span-1 text-[10px] text-primary-800 uppercase">¿Su Asesor Financiero le ha requerido el pago de comisión por el trámite del crédito?</label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpAsesorPideComision" value="SI" 
                                        checked={options.selectedOpAsesorPideComision === 'SI'}
                                        onChange={handleOptionChange}
                                        />
                                        SI
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpAsesorPideComision" value="NO" 
                                        checked={options.selectedOpAsesorPideComision === 'NO'}
                                        onChange={handleOptionChange}
                                        />
                                        NO
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/**COMENTARIOS DEL SOCIO */}
                        <div className=" px-2 pb-2">
                            <label htmlFor="" className="ml-2 text-[10px] text-primary-800">COMENTARIOS O SUGERENCIA DEL SOCIO</label>
                            <textarea placeholder="Escribe aquí un mensaje" id="message" rows="4" className="mt-1 p-2 block w-full px-3 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                            focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                            invalid:border-pink-500 invalid:text-pink-600
                            focus:invalid:border-pink-500 focus:invalid:ring-pink-500" 
                            >
                            </textarea>
                        </div>
                        {/**COMENTARIOS DEL ENTREVISTADOR */}
                        <div className=" px-2 pb-2">
                            <label htmlFor="" className="ml-2 text-[10px] text-primary-800">COMENTARIOS O SUGERENCIA DEL ENTREVISTADOR</label>
                            <textarea placeholder="Escribe aquí un mensaje" id="message" rows="4" className="mt-1 p-2 block w-full px-3 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                            focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                            invalid:border-pink-500 invalid:text-pink-600
                            focus:invalid:border-pink-500 focus:invalid:ring-pink-500" >
                            </textarea>
                        </div>
                    </div>

                        <div className="relative overflow-x-auto my-4 shadow-md sm:rounded-lg">
                            
                        </div>

                        <div className="mb-2">
                            <div className="grid grid-cols-4 gap-4 justify-center items-center ml-8 my-2">
                                <label htmlFor="" className="text-[10px] text-primary-800">¿LA INFORMACIÓN DEL SOCIO ES CORRECTA?</label>
                                <div className="col-span-3 inline-flex gap-8">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpInformacionVerificada" value="SI" 
                                        checked={options.selectedOpInformacionVerificada === 'SI'}
                                        onChange={handleOptionChange}
                                        />
                                        SI
                                    </label>
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input className="my-1" type="radio" name="selectedOpInformacionVerificada" value="NO" 
                                        checked={options.selectedOpInformacionVerificada === 'NO'}
                                        onChange={handleOptionChange}
                                        />
                                        NO, PROCEDER A ENVIAR ACTA A ASESOR FINANCIERO
                                    </label>
                                </div>
                            </div>
                            
                        </div>
                    
                    <div className='flex justify-end p-2 border-t-2 border-primary-800 gap-4'>
                        <button type="submit" className='bg-primary-700 text-primary-50 rounded border-2 border-primary-800 p-2.5 hover:bg-primary-50 hover:text-primary-800'>Procesar</button>
                        <button type="button" onClick={onClose} className='bg-red-500 text-primary-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500'>Cerrar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}