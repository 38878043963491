import React, { useState, useContext, useEffect } from "react";
import { UserContext } from '../UserContext.js';
import Navbar from "../navbar/navBar";
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import CardExpediente_carpetas from "../common/card/CardExpediente_carpetas";
import Loader from "../common/Loader.jsx";

export default function RevisarExpedientes(){
    const { userData, setUserData } = useContext(UserContext);
    const [agencia,setAgencias]=useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchAgencias = async () => {
        try {
            const agenciasResponse = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/get-carpetas-agencia-user',{
                method:'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify({user:userData.DNI})  
            });
            const agenciasData = await agenciasResponse.json();
            setAgencias(agenciasData.data);
        }catch(error){
            console.error('Error fetching options:', error);
        }finally{
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchAgencias();
    }, []);
    
    return(
        <>
        {isLoading && <Loader />}
        <Navbar/>
        <SidebarExpedientes/>
        {!isLoading && (<>
            <div className="p-4 sm:ml-64">
                <h1 className="font-light text-primary-800 m-2 text-xl"><span className="font-bold">{"Revisar Expedientes"}</span></h1>
                <div className="p-4 border-2 grid grid-cols-1 gap-4 border-gray-200 border-dashed rounded-lg">
                    <div className="w-full lg:grid lg:grid-cols-4 lg:gap-4 smm:grid-cols-1 smm:justify-center smm:items-center">
                    {agencia && agencia.map((age) => (
                            <CardExpediente_carpetas
                                key={age.cod_a}
                                icons={'/icons/icons_agencia.svg'}
                                link={`/expedientes/revisar_expediente/${age.Agencia}`}
                                title={age.Agencia}
                            />
                        ))}   
                    </div>
                </div>
            </div>
        </>)}
    </>
    );
}