import React, { useEffect, useContext, useState } from "react";
import Navbar from "../navbar/navBar";
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import CardExpediente from "../common/card/CardExpediente";
import { UserContext } from '../UserContext.js';
import Loader from "../common/Loader.jsx";

export default function Inicio() {
    const { userData } = useContext(UserContext);
    const [data, setData] = useState('');
    const [dataEx, setDataEx] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [mesActual, setMesActual] = useState("");
    const [anioActual, setAnioActual] = useState("");
    const meses = ["01 ENERO","02 FEBRERO","03 MARZO","04 ABRIL","05 MAYO","06 JUNIO",
        "07 JULIO","08 AGOSTO","09 SEPTIEMBRE","10 OCTUBRE","11 NOVIEMBRE","12 DICIEMBRE",];

    const fetchData = async () => {
        if (!userData || !userData.DNI) return;

        switch (userData.CARGO) {
            case 'ANALISTA DE CREDITOS I':
                try {
                    const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-sql/api/getNumDesembolsos', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ DNI: userData.DNI })
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const result = await response.json();
                    if (!result || !Array.isArray(result)) {
                        throw new Error('Invalid data format received');
                    }
                    setData(result[0]['NUMDESEMBOLSOS'] || ''); // Manejar caso de valor no definido
                } catch (error) {
                    setError(error.message); // Actualizar el estado con el mensaje del error
                }finally {
                setIsLoading(false); // Establecer isLoading en false cuando los datos hayan sido cargados
                }
                break;
            case 'ADMINISTRADOR':
                try {
                    const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-sql/api/getNumDesembolsosAdministrador', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ DNI: userData.DNI })
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const result = await response.json();
                    if (!result || !Array.isArray(result)) {
                        throw new Error('Invalid data format received');
                    }
                    setData(result[0]['NUMDESEMBOLSOS'] || ''); // Manejar caso de valor no definido
                } catch (error) {
                    setError(error.message); // Actualizar el estado con el mensaje del error
                }finally {
                    setIsLoading(false); // Establecer isLoading en false cuando los datos hayan sido cargados
                    }
                break;
            default:
                try {
                    const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-sql/api/getNumDesembolsosGlobal', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ DNI: userData.DNI })
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const result = await response.json();
                    if (!result || !Array.isArray(result)) {
                        throw new Error('Invalid data format received');
                    }
                    setData(result[0]['NUMDESEMBOLSOS'] || ''); // Manejar caso de valor no definido
                } catch (error) {
                    setError(error.message); // Actualizar el estado con el mensaje del error
                }finally {
                    setIsLoading(false); // Establecer isLoading en false cuando los datos hayan sido cargados
                    }
                break;
        }
    };

    const fetchDataExpediente = async () => {
        if (!userData || !userData.DNI) return;

        const fechaActual = new Date();
        const mesNumerico = fechaActual.getMonth(); 
        const mesEncontrado = meses[mesNumerico];
        const anio = fechaActual.getFullYear();
        setAnioActual(anio);
        setMesActual(mesEncontrado);
        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/getNumberExpedientes', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ DNI:userData.DNI,cargo:userData.CARGO,agencia:userData.AGENCIA,anio:anioActual,mes:mesActual })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if (!result || !Array.isArray(result)) {
                throw new Error('Invalid data format received');
            }
            setDataEx(result[0]['NUM_EXPEDIENTES'] || ''); // Manejar caso de valor no definido
        } catch (error) {
            setError(error.message); // Actualizar el estado con el mensaje del error
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataExpediente();
    }, [userData]);

    const desem = parseInt(data, 10) || 0;
    const expe = parseInt(dataEx, 10) || 0;
    const diferencia = desem - expe;

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    return (
        <>
        {isLoading && <Loader />}
            <Navbar />
            <SidebarExpedientes />
            {!isLoading && (<>
            {userData.CARGO === 'ANALISTA DE CREDITOS I' ? (
                <div className="p-4 sm:ml-64">
                    <h1 className="font-bold text-primary-800 m-2 text-xl">INICIO - {userData.CARGO}</h1>
                    <div className="p-4 border-2 gap-4 border-gray-200 border-dashed rounded-lg">
                        <p className="text-primary-800 font-normal mb-2">PERIODO {year}{month}</p>
                        <div className="w-full lg:grid lg:grid-cols-3 lg:gap-4 smm:grid-cols-1 smm:justify-center smm:items-center">
                            <CardExpediente
                                key="001"
                                icons="/icons/icons_desembolso.svg"
                                title="DESEMBOLSOS"
                                num={data === '' ? '' : data}
                            />
                            <CardExpediente
                                key="002"
                                icons="/icons/icons_expediente.svg"
                                title="EXPEDIENTES"
                                num={dataEx === '' ? '' : dataEx}
                            />
                        </div>
                    </div>
                    {diferencia !== 0 && (
                        <div className="inline-flex mt-6" >
                            <div>
                                <img
                                    src={`${process.env.PUBLIC_URL}/icons/icons_advertencia.svg`}
                                    loading="lazy"
                                    className="w-6 h-6"
                                />
                            </div>
                            <div className="ml-2 text-orange-500">
                                <p>
                                    Tiene <span className="font-semibold">{diferencia < 0 ? 'Error' : diferencia}</span> expedientes por regularizar
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            ) : userData.CARGO === 'ADMINISTRADOR' ? (
                <div className="p-4 sm:ml-64">
                    <h1 className="font-bold text-primary-800 m-2 text-xl">INICIO - {userData.CARGO}</h1>
                    <div className="p-4 border-2 gap-4 border-gray-200 border-dashed rounded-lg">
                        {/* Aquí puedes agregar contenido específico para ADMINISTRADOR */}
                        <p className="text-primary-800 font-normal mb-2">PERIODO {year}{month}</p>
                        <div className="w-full lg:grid lg:grid-cols-3 lg:gap-4 smm:grid-cols-1 smm:justify-center smm:items-center">
                            <CardExpediente
                                key="001"
                                icons="/icons/icons_desembolso.svg"
                                title="DESEMBOLSOS"
                                num={data === '' ? '' : data}
                            />
                            <CardExpediente
                                key="002"
                                icons="/icons/icons_expediente.svg"
                                title="EXPEDIENTES"
                                num={dataEx === '' ? '' : dataEx}
                            />
                        </div>
                    </div>
                    {diferencia !== 0 && (
                        <div className="inline-flex mt-6" >
                            <div>
                                <img
                                    src={`${process.env.PUBLIC_URL}/icons/icons_advertencia.svg`}
                                    loading="lazy"
                                    className="w-6 h-6"
                                />
                            </div>
                            <div className="ml-2 text-orange-500">
                                <p>
                                    Tiene <span className="font-semibold">{diferencia < 0 ? 'Error' : diferencia}</span> expedientes por regularizar
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            ) :
                <div className="p-4 sm:ml-64">
                <h1 className="font-bold text-primary-800 m-2 text-xl">VISTA GLOBAL</h1>
                    <div className="w-full p-4 border-2 gap-4 border-gray-200 border-dashed rounded-lg ">
                        {/* Aquí puedes agregar contenido específico para ADMINISTRADOR */}
                        <p className="text-primary-800 font-normal mb-2">PERIODO {year}{month}</p>
                        <div className="w-full lg:grid lg:grid-cols-3 lg:gap-4 smm:grid-cols-1 smm:justify-center smm:items-center">
                            <CardExpediente
                                key="001"
                                icons="/icons/icons_desembolso.svg"
                                title="DESEMBOLSOS"
                                num={data === '' ? '' : data}
                            />
                            <CardExpediente
                                key="002"
                                icons="/icons/icons_expediente.svg"
                                title="EXPEDIENTES"
                                num={dataEx === '' ? '' : dataEx}
                            />
                        </div>
                    </div>
                    {diferencia !== 0 && (
                        <div className="inline-flex mt-6" >
                            <div>
                                <img
                                    src={`${process.env.PUBLIC_URL}/icons/icons_advertencia.svg`}
                                    loading="lazy"
                                    className="w-6 h-6"
                                />
                            </div>
                            <div className="ml-2 text-orange-500">
                                <p>
                                    Tiene <span className="font-semibold">{diferencia < 0 ? 'Error' : diferencia}</span> expedientes por regularizar
                                </p>
                            </div>
                        </div>
                    )}
                </div> 
            }</>)}
        </>
    );
}
