import React, { useState, useContext } from "react";
import InputImageCamera from "../InputImageCamera";
import { UserContext } from '../../UserContext.js';

export default function ModalVerificarUbicacion({ isOpen, onClose, coord }) {
    const { userData } = useContext(UserContext);
    const [reSuministro,setReSuministro]=useState('');
    const [datos, setDatos] = useState(null);
    const [image, setImage] = useState({
        a_image_foto_fachada: null,
        a_image_selfie_fachada: null,
        a_image_negocio_fachada: null,
        a_image_selfie_negocio_fachada: null
    });

    const initialFormData = {
        dni: "",
        socio: "",
        suministro: "",
        direccion: "",
        ref_vehiculo: "",
        ref_paradero: "",
        ref_adicional: '',
        suministro_negocio: '',
        direccion_negocio: '',
        ref_vehiculo_negocio: "",
        ref_paradero_negocio: "",
        ref_adicional_negocio: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [options, setOptions] = useState({ selectedOption: '' });
    const [optionsS, setOptionsS] = useState({ selectedOptionNegocio: '' });

    const handleOptionChange = (event) => {
        setFormData(initialFormData);
        setDatos(null);
        setOptions({ selectedOption: event.target.value });
    };

    const handleOptionChangeS = (event) => {
        setOptionsS({ selectedOptionNegocio: event.target.value });
    };

    const handleImageChangeIn = (id, file) => {
        setImage(prevFiles => ({ ...prevFiles, [id]: file }));
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevFormData => ({ ...prevFormData, [id]: value }));
    };

    const VerificarSocioReniec = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://api.factiliza.com/v1/dni/info/${formData.dni}`, {
                headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3MTIiLCJuYW1lIjoiQXhlbCBTYWlyZSIsImVtYWlsIjoiYXhlbHMuc2FpcmVAZ21haWwuY29tIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiY29uc3VsdG9yIn0.etp6PHyRzLemnpaxd36-tnQB5B2OQ_A3UIazJx3pQhA'
                 }
            });
            const socioRe = await response.json();
            if (socioRe.status === 'error') {
                alert('Ingrese un DNI correcto');
                setFormData(prevFormData => ({ ...prevFormData, socio: '' }));
            } else {
                const socio = `${socioRe.data.nombres} ${socioRe.data.apellido_paterno} ${socioRe.data.apellido_materno}`;
                setFormData(prevFormData => ({ ...prevFormData, socio }));
                ComprobarSocioEnBD();
            }
        } catch (error) {
            console.error('Error fetching socio:', error);
        }
    };
    const VerificarSuministro = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://ram-special-evenly.ngrok-free.app/api-mongo/api/revisar_suministro`, {
                method: 'POST',
                headers: { "ngrok-skip-browser-warning": "69420" },
                body: JSON.stringify({ suministro:formData.suministro })
            });
            const suministroRe = await response.json();
            if(suministroRe.status==true){
                setReSuministro(suministroRe);
            }else{
                setReSuministro('');
            }
            
        } catch (error) {
            console.error('Error fetching socio:', error);
        }
    };

    const ComprobarSocioEnBD = async () => {
        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/revisar_ingresos_geodile', {
                method: 'POST',
                headers: { "ngrok-skip-browser-warning": "69420" },
                body: JSON.stringify({ dni_socio: formData.dni, tipo_ubicacion: options.selectedOption })
            });
            const result = await response.json();
            setDatos(result[0]['status']==true?true:false);
        } catch (error) {
            console.error('Error checking socio in DB:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (datos === false) {
                const formDataWithFiles = new FormData();
                const commonFields = {
                    user: userData.DNI,
                    dni_socio: formData.dni,
                    socio: formData.socio,
                    tipo_ubicacion: options.selectedOption,
                    latitud: coord.lat,
                    longitud: coord.lng
                };

                if (options.selectedOption === 'DOMICILIO') {
                    Object.assign(commonFields, {
                        suministro: formData.suministro,
                        direccion: formData.direccion,
                        ref_vehiculo: formData.ref_vehiculo,
                        ref_paradero: formData.ref_paradero,
                        ref_adicional: formData.ref_adicional,
                        condicion_negocio: optionsS.selectedOptionNegocio
                    });
                } else if (options.selectedOption === 'NEGOCIO') {
                    Object.assign(commonFields, {
                        suministro: formData.suministro_negocio,
                        direccion: formData.direccion_negocio,
                        ref_vehiculo: formData.ref_vehiculo_negocio,
                        ref_paradero: formData.ref_paradero_negocio,
                        ref_adicional: formData.ref_adicional_negocio,
                        condicion_negocio: "NEGOCIO EN OTRO LUGAR"
                    });
                }

                // Agrega los campos comunes y archivos
                Object.entries(commonFields).forEach(([key, value]) => {
                    formDataWithFiles.append(key, value);
                });
                Object.entries(image).forEach(([key, file]) => {
                    formDataWithFiles.append(key, file);
                });
    
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/verificar-pre_desembolso', {
                    method: 'POST',
                    headers: { "ngrok-skip-browser-warning": "69420" },
                    body: formDataWithFiles
                });
                const result = await response.json();
                if (result[0]?.status === true) {
                    alert('SE ENVIO CORRECTAMENTE');
                    setFormData(initialFormData);
                    document.querySelectorAll('input[type="file"]').forEach(input => input.value = "");
                    onClose();
                } else {
                    alert(result[0]?.message || 'Error desconocido');
                }
            }else{
                alert('datos encontrados en BD')
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    if (!isOpen) return null;
    if (!coord) return <div>Cargando...</div>;

    return (
        <>
            <div className="modal-overlay">
                <div className="modal-content lg:w-[50%] smm:w-[90%] max-h-[90vh] overflow-y-auto">
                    <div className="flex justify-end">
                        <button onClick={onClose} className="text-primary-800">
                            <svg
                                className="w-8 h-8"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="px-2 border-b-2 border-primary-800">
                        <h2 className="mb-2 lg:text-xl smm:text-sm font-semibold text-primary-800 uppercase">
                            Verificar Ubicacion
                        </h2>
                    </div>
                    <form className="w-full mx-auto" onSubmit={(e)=>handleSubmit(e)}>

                        <div className="grid grid-cols-1">
                            <label htmlFor="" className="col-span-2 text-[10px] text-primary-800">¿QUE VERIFICACIÓN DESEA AGREGAR?</label>
                            <div className="grid grid-cols-5 items-center gap-4">
                                <div className="col-span-3 inline-flex gap-4">
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input 
                                        className="my-1"
                                        
                                        type="radio" name="selectedOption"
                                        value="DOMICILIO"
                                        checked={options.selectedOption === 'DOMICILIO'}
                                        onChange={handleOptionChange}
                                        />
                                        DOMICILIO
                                    </label>
                                    <br />
                                    <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                        <input 
                                        className="my-1" type="radio" name="selectedOption"
                                        value="NEGOCIO"
                                        checked={options.selectedOption === 'NEGOCIO'}
                                        onChange={handleOptionChange}
                                        />
                                        NEGOCIO
                                    </label>
                                </div>
                            </div>
                        </div>

                            <div className="w-full">
                                <label
                                    htmlFor="dni"
                                    className="block mb-0 text-[12px] font-medium text-primary-900"
                                >
                                    DNI
                                </label>
                                <div className="inline-flex w-full">
                                    <input
                                        type="number"
                                        id="dni"
                                        value={formData.dni}
                                        onChange={handleChange}
                                        className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-s-lg focus:ring-primary-800 focus:border-primary-800 block w-10/12 p-2.5"
                                        placeholder="DNI"
                                    />
                                    <button
                                    onClick={VerificarSocioReniec} 
                                    className="bg-primary-800 w-2/12 rounded-e-lg">
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            className="h-10 w-14  border-r border-transparent stroke-primary-50 px-3.5 peer-focus:border-primary-800 peer-focus:stroke-primary-800" 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke="currentColor" 
                                            strokeWidth="2"
                                            >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-2 col-span-2 mb-2">
                                <label
                                    htmlFor="suministro"
                                    className="block mb-0 text-sm font-medium text-primary-900"
                                >
                                    SOCIO
                                </label>
                                <input
                                    type="text"
                                    id="socio"
                                    value={`${formData.socio}`}
                                    onChange={handleChange}
                                    className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5"
                                    placeholder="NOMBRE Y APELLIDOS"
                                    required
                                />
                            </div>

                        {options.selectedOption=='DOMICILIO' && formData.socio ?(
                            datos===true?
                            <div className="grid grid-cols-1 justify-items-center rounded-lg border-2 border-primary-800 p-1 my-2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/icons/icons_advertencia.svg`}
                                    loading="lazy"
                                    className="w-6 h-6"
                                />
                                <p className="mt-2 text-primary-800 text-[12px]">EL SOCIO YA TIENE UNA VERIFICACIÓN ACTUAL</p>
                            </div>
                            :
                                <div className="grid grid-cols-1 rounded-lg border-2 border-primary-800 p-1 my-2">
                                    <h3 className="text-primary-800 text-[12px]">INGRESE DATOS DEL DOMICILIO</h3>
                                    <div className="mt-2 grid grid-cols-1 gap-2">
                                        <div>
                                            <label
                                                htmlFor="coords"
                                                className="block mb-0 text-[12px] font-medium text-primary-900"
                                            >
                                                TUS COORDENADAS
                                            </label>
                                            <input
                                                disabled
                                                type="text"
                                                id="coords"
                                                value={`${coord.lat} ; ${coord.lng}`}
                                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-[12px] rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5"
                                                placeholder="COORDENADAS"
                                            />
                                        </div>
                                        
                                        <div className="mt-2 col-span-2">
                                            <label
                                                htmlFor="suministro"
                                                className="block mb-0 text-sm font-medium text-primary-900"
                                            >
                                                SUMINISTRO
                                            </label>
                                            <div className="inline-flex w-full">
                                                <input
                                                    type="number"
                                                    id="suministro"
                                                    value={formData.suministro}
                                                    onChange={handleChange}
                                                    className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-s-lg focus:ring-primary-800 focus:border-primary-800 block w-10/12 p-2.5"
                                                    placeholder="SUMINISTRO"
                                                    required
                                                />
                                                <button
                                                    onClick={VerificarSuministro} 
                                                    className="bg-primary-800 w-2/12 rounded-e-lg">
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            className="h-10 w-14  border-r border-transparent stroke-primary-50 px-3.5 peer-focus:border-primary-800 peer-focus:stroke-primary-800" 
                                                            fill="none" 
                                                            viewBox="0 0 24 24" 
                                                            stroke="currentColor" 
                                                            strokeWidth="2"
                                                            >
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                        </svg>
                                                </button>
                                            </div>
                                        </div>

                                        {reSuministro && (
                                            <div className="text-[12px] text-primary-800 mx-2 p-2 border-2 border-secondary-400 rounded-xl">
                                                <h1 className="text-center font-semibold">REPORTE DE DUPLICIDAD</h1>
                                                <p className="uppercase text-[10px]">Ingresado por: {reSuministro.responsable}</p>
                                                <p className="uppercase text-[10px]">En {reSuministro.agencia}</p>
                                                <p className="uppercase text-[10px]">para la socia: {reSuministro.socio}</p>
                                                <p className="uppercase text-[10px]">Fecha: {reSuministro.fecha_re} Hora: {reSuministro.hora_re}</p>
                                            </div>
                                        )}
                                        
                                        <div className="mt-2 col-span-2">
                                            <label
                                                htmlFor="suministro"
                                                className="block mb-0 text-sm font-medium text-primary-900"
                                            >
                                                DIRECCIÓN
                                            </label>
                                            <input
                                                type="text"
                                                id="direccion"
                                                value={formData.direccion}
                                                onChange={handleChange}
                                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5"
                                                placeholder="DIRECCION"
                                                required
                                            />
                                        </div>
                                        <div className="mt-2 col-span-2">
                                            <label
                                                htmlFor="ref_vehiculo"
                                                className="block mb-0 text-sm font-medium text-primary-900"
                                            >
                                                REFERENCIA DE TRANSPORTE
                                            </label>
                                            <textarea placeholder="¿Que transporte urbano?" 
                                            id="ref_vehiculo" rows="4" 
                                            className="mt-1 block w-full px-3 py-2 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                                            focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                                            disabled:bg-secondary-50 disabled:text-primary-800 disabled:border-secondary-500 disabled:shadow-none
                                            invalid:border-primary-800 invalid:text-primary-800
                                            focus:invalid:border-secondary-400 focus:invalid:ring-secondary-400" 
                                            value={formData.ref_vehiculo}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        <div className="mt-2 col-span-2">
                                            <label
                                                htmlFor="ref_paradero"
                                                className="block mb-0 text-sm font-medium text-primary-900"
                                            >
                                                REFERENCIA DE PARADERO
                                            </label>
                                            <textarea placeholder="¿En que paradero me debo de bajar?" 
                                            rows="4" 
                                            className="mt-1 block w-full px-3 py-2 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                                            focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                                            disabled:bg-secondary-50 disabled:text-primary-800 disabled:border-secondary-500 disabled:shadow-none
                                            invalid:border-primary-800 invalid:text-primary-800
                                            focus:invalid:border-secondary-400 focus:invalid:ring-secondary-400" 
                                            id="ref_paradero"
                                            value={formData.ref_paradero}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        <div className="mt-2 col-span-2">
                                            <label
                                                htmlFor="ref_precisa"
                                                className="block mb-0 text-sm font-medium text-primary-900"
                                            >
                                                REFERENCIA ADICIONALES
                                            </label>
                                            <textarea placeholder="¿Escribe referencias adicionales?" 
                                            rows="4" 
                                            className="mt-1 block w-full px-3 py-2 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                                            focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                                            disabled:bg-secondary-50 disabled:text-primary-800 disabled:border-secondary-500 disabled:shadow-none
                                            invalid:border-primary-800 invalid:text-primary-800
                                            focus:invalid:border-secondary-400 focus:invalid:ring-secondary-400" 
                                            id="ref_adicional"
                                            value={formData.ref_adicional}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1">
                                        <InputImageCamera id={'a_image_foto_fachada'} title={'CAPTURAR FACHADA DE VIVIENDA'} handleImageChangeIn={handleImageChangeIn}/>
                                        <InputImageCamera id={'a_image_selfie_fachada'} title={'CAPTURAR SELFIE CON VIVIENDA'} handleImageChangeIn={handleImageChangeIn}/>
                                    </div>

                                    <div className="grid grid-cols-1">
                                            <label htmlFor="" className="col-span-2 text-[10px] text-primary-800">¿EL NEGOCIO ES ESTA MISMA DIRECCIÓN?</label>
                                            <div className="w-full grid grid-cols-1 justify-between">
                                                <div className="inline-flex justify-between">
                                                    <label className="flex justify-center items-center gap-1 text-[10px] text-primary-800">
                                                        <input 
                                                        className="my-1"
                                                        
                                                        type="radio" name="selectedOptionNegocio"
                                                        value="SI"
                                                        checked={optionsS.selectedOptionNegocio === 'SI'}
                                                        onChange={handleOptionChangeS}
                                                        />
                                                        SI
                                                    </label>
                                                    <br />
                                                    <label className="flex justify-center items-center gap-1 text-[10px] text-primary-800">
                                                        <input 
                                                        className="my-1" type="radio" name="selectedOptionNegocio"
                                                        value="NEGOCIO EN OTRO LUGAR"
                                                        checked={optionsS.selectedOptionNegocio === 'NEGOCIO EN OTRO LUGAR'}
                                                        onChange={handleOptionChangeS}
                                                        />
                                                        NO, EN OTRO LUGAR
                                                    </label>
                                                    <br />
                                                    <label className="flex justify-center items-center gap-1 text-[10px] text-primary-800">
                                                        <input 
                                                        className="my-1" type="radio" name="selectedOptionNegocio"
                                                        value="NO TIENE"
                                                        checked={optionsS.selectedOptionNegocio === 'NO TIENE'}
                                                        onChange={handleOptionChangeS}
                                                        />
                                                        NO TIENE NEGOCIO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                        
                        ):(options.selectedOption=='NEGOCIO' && formData.socio?
                            datos===true?
                            <div className="grid grid-cols-1 justify-items-center rounded-lg border-2 border-primary-800 p-1 my-2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/icons/icons_advertencia.svg`}
                                    loading="lazy"
                                    className="w-6 h-6"
                                />
                                <p className="mt-2 text-primary-800 text-[12px]">EL SOCIO YA TIENE UNA VERIFICACIÓN ACTUAL</p>
                            </div>
                            :
                        <div className="grid grid-cols-1 border-2 border-primary-800 p-1 my-2">
                            <h3 className="text-primary-800 text-[12px]">INGRESE DATOS DEL NEGOCIO</h3>
                            <div className="mt-2 grid grid-cols-1 gap-2">
                                <div>
                                    <label
                                        htmlFor="coords"
                                        className="block mb-0 text-[12px] font-medium text-primary-900"
                                    >
                                        TUS COORDENADAS
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        id="coords_negocio"
                                        value={`${coord.lat} ; ${coord.lng}`}
                                        className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-[12px] rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5"
                                        placeholder="COORDENADAS"
                                    />
                                </div>                                
                                <div className="mt-2 col-span-2">
                                    <label
                                        htmlFor="suministro"
                                        className="block mb-0 text-sm font-medium text-primary-900"
                                    >
                                        SUMINISTRO
                                    </label>
                                    <input
                                        type="number"
                                        id="suministro_negocio"
                                        value={formData.suministro_negocio}
                                        onChange={handleChange}
                                        className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5"
                                        placeholder="SUMINISTRO"
                                        required
                                    />
                                </div>
                                <div className="mt-2 col-span-2">
                                    <label
                                        htmlFor="suministro"
                                        className="block mb-0 text-sm font-medium text-primary-900"
                                    >
                                        DIRECCIÓN
                                    </label>
                                    <input
                                        type="text"
                                        id="direccion_negocio"
                                        value={formData.direccion_negocio}
                                        onChange={handleChange}
                                        className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5"
                                        placeholder="DIRECCION"
                                        required
                                    />
                                </div>
                                <div className="mt-2 col-span-2">
                                    <label
                                        htmlFor="ref_vehiculo"
                                        className="block mb-0 text-sm font-medium text-primary-900"
                                    >
                                        REFERENCIA DE TRANSPORTE
                                    </label>
                                    <textarea placeholder="¿Que transporte urbano?" 
                                    rows="4" 
                                    className="mt-1 block w-full px-3 py-2 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                                    focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                                    disabled:bg-secondary-50 disabled:text-primary-800 disabled:border-secondary-500 disabled:shadow-none
                                    invalid:border-primary-800 invalid:text-primary-800
                                    focus:invalid:border-secondary-400 focus:invalid:ring-secondary-400" 
                                    id="ref_vehiculo_negocio"
                                    value={formData.ref_vehiculo_negocio}
                                    onChange={handleChange}
                                    required
                                    />
                                </div>
                                <div className="mt-2 col-span-2">
                                    <label
                                        htmlFor="ref_paradero!"
                                        className="block mb-0 text-sm font-medium text-primary-900"
                                    >
                                        REFERENCIA DE PARADERO
                                    </label>
                                    <textarea placeholder="¿Que transporte urbano?" 
                                    rows="4" 
                                    className="mt-1 block w-full px-3 py-2 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                                    focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                                    disabled:bg-secondary-50 disabled:text-primary-800 disabled:border-secondary-500 disabled:shadow-none
                                    invalid:border-primary-800 invalid:text-primary-800
                                    focus:invalid:border-secondary-400 focus:invalid:ring-secondary-400" 
                                    id="ref_paradero_negocio"
                                    value={formData.ref_paradero_negocio}
                                    onChange={handleChange}
                                    required
                                    />
                                </div>
                                <div className="mt-2 col-span-2">
                                    <label
                                        htmlFor="ref_precisa_"
                                        className="block mb-0 text-sm font-medium text-primary-900"
                                    >
                                        REFERENCIA ADICIONALES
                                    </label>
                                    <textarea placeholder="¿Que transporte urbano?" 
                                    rows="4" 
                                    className="mt-1 block w-full px-3 py-2 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                                    focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                                    disabled:bg-secondary-50 disabled:text-primary-800 disabled:border-secondary-500 disabled:shadow-none
                                    invalid:border-primary-800 invalid:text-primary-800
                                    focus:invalid:border-secondary-400 focus:invalid:ring-secondary-400" 
                                    id="ref_adicional_negocio"
                                    value={formData.ref_adicional_negocio}
                                    onChange={handleChange}
                                    required
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1">
                                <InputImageCamera id={'a_image_negocio_fachada'} title={'CAPTURAR FACHADA DE NEGOCIO'} handleImageChangeIn={handleImageChangeIn}/>
                                <InputImageCamera id={'a_image_selfie_negocio_fachada'} title={'CAPTURAR SELFIE CON NEGOCIO'} handleImageChangeIn={handleImageChangeIn}/>
                            </div>
                        </div>
                    
                        :'')}

                    <div className="flex justify-end p-2 border-t-2 border-primary-800 gap-4">
                        {!reSuministro && (
                            <button type="submit" className=" bg-primary-700 text-primary-50 rounded border-2 border-primary-800 p-2.5 hover:bg-primary-50 hover:text-primary-800">GUARDAR</button>
                        )}
                        <button type="button" onClick={onClose} className="bg-red-500 text-primary-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500">CERRAR</button>
                    </div>
                    </form>
                </div>
            </div>
        </>
    );
}
