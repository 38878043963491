import React, { useState, useContext } from "react";
import { UserContext } from '../../UserContext.js';

export default function ModalRevisarExpediente({ isOpen, onClose, chec_expe, showToast }) {
    const { userData } = useContext(UserContext);
    const [mensaje, setMensaje] = useState('');
    const [formData, setFormData] = useState({
        solicitud_credito: '',
        reporte_sentinel: '',
        dni: '',
        cronograma_pago: '',
        reporte_ubicacion: '',
        foto_negocio: '',
        evaluacion_credito: '',
        evidencias: ''
    });
    const [errors, setErrors] = useState({}); // Para almacenar errores de validación

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        // Verificar que todos los campos no estén vacíos
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = 'Este campo es obligatorio';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevenir el comportamiento por defecto del formulario

        if (!validateForm()) {
            return; // Si hay errores, no enviar el formulario
        }

        const data = {
            cod_exp: chec_expe.cod_exp,
            dni_socio: chec_expe.dni_socio,
            nom_ape: chec_expe.nom_ape,
            revisado_por: userData.DNI,
            comentario:mensaje,
            ...formData
        };

        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/insert-revision-expe', {
                method: 'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const result = await response.json();
                if(result.status==true){
                    showToast('add_revision');
                    setFormData({
                        solicitud_credito: '',
                        reporte_sentinel: '',
                        dni: '',
                        cronograma_pago: '',
                        reporte_ubicacion: '',
                        foto_negocio: '',
                        evaluacion_credito: '',
                        evidencias: ''
                    });
                    setMensaje('');
                    onClose()
                }
            } else {
                alert('Error al enviar datos');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error al enviar datos');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay ">
            <div className="modal-content lg:w-[50%] smm:w-[90%] max-h-[90vh] overflow-y-auto ">
                <div className='px-2 border-b-4 border-primary-800'>
                    <h2 className='mb-0 text-xl font-semibold text-primary-800'>REVISAR EXPEDIENTE</h2>
                </div>
                <form className="w-full mx-auto" onSubmit={handleSubmit}>
                    <div className='grid grid-cols-1 gap-4 mt-4'>
                        <div>
                            <label htmlFor="EXPE" className="block mb-0 text-sm font-medium text-primary-900">CODIGO DE EXPEDIENTE:  {chec_expe.cod_exp}</label>
                        </div>
                        <div className="col-span-1">
                            <label htmlFor="ARAZON" className="block mb-0 text-sm font-medium text-primary-900">PERTENECE A SOCIO: {chec_expe.nom_ape} con DNI: {chec_expe.dni_socio}</label>
                        </div>
                        <div className="col-span-1">
                            <label htmlFor="revisa" className="block mb-0 text-sm font-medium text-primary-900">REVISADO POR: {userData.DNI}</label>
                        </div>
                    </div>

                    <div className="relative overflow-x-auto ">
                        {[
                            { label: "SOLICITUD DE CREDITO", id: "solicitud_credito" },
                            { label: "REPORTE DE SENTINEL", id: "reporte_sentinel" },
                            { label: "DOCUMENTO - DNI", id: "dni" },
                            { label: "CRONOGRAMA DE PAGOS", id: "cronograma_pago" },
                            { label: "REPORTE DE UBICACIÓN", id: "reporte_ubicacion" },
                            { label: "FOTOS DE NEGOCIO", id: "foto_negocio" },
                            { label: "EVALUACIÓN", id: "evaluacion_credito" },
                            { label: "EVIDENCIAS", id: "evidencias" }
                        ].map((item) => (
                            <div key={item.id} className="grid grid-cols-2  mb-0 p-1 justify-center items-center">
                                <div>
                                    <p className="text-primary-800 font-bold lg:text-sm smm:text-[11px] ">{item.label}</p>
                                    {errors[item.id] && <p className="text-red-500 text-sm">{errors[item.id]}</p>}
                                </div>
                                <select
                                    id={item.id}
                                    className={`bg-primary-50 border border-primary-800 text-primary-800 lg:text-sm smm:text-[11px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 ${errors[item.id] ? 'border-red-500' : ''}`}
                                    value={formData[item.id]}
                                    onChange={handleChange}
                                >
                                    <option value="">SELECCIONE...</option>
                                    <option value="3">COMPLETO</option>
                                    <option value="2">INCOMPLETO</option>
                                    <option value="1">EQUIVOCADO</option>
                                    <option value="0">NO EXISTE</option>
                                </select>
                            </div>
                        ))}
                        <div className='grid grid-cols-1 my-4'>  
                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-primary-800 ">ESCRIBA UN COMENTARIO</label>
                            <textarea placeholder="Escribe aquí un comentario" id="message" rows="4" className="mt-1 block w-full px-3 py-2 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-800
                                focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500" 
                                value={mensaje}
                                onChange={(e) => setMensaje(e.target.value)}>
                            </textarea>
                        </div>
                    </div>

                    <div className='flex justify-end p-1 border-t-2 border-primary-800 gap-4'>
                        <button type="submit" className='bg-primary-800 text-primary-50 rounded-lg border-2 border-primary-100 p-1.5 ml-2 hover:bg-primary-50 hover:text-primary-800'>
                            Revisar
                        </button>
                        <button type="button" onClick={onClose} className='bg-red-500 text-primary-50 rounded border-2 border-red-500 p-1.5 ml-2 hover:bg-red-50 hover:text-red-500'>
                            Cerrar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
