import React, { createContext, useState, useEffect } from 'react';

// Crea el contexto
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        // Recuperar los datos del usuario desde el almacenamiento local
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const parsedUserData=JSON.parse(storedUserData);
            setUserData(parsedUserData);
        }
    }, []);

    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            {children}
        </UserContext.Provider>
    );
};
