import React, { useEffect, useState ,useContext} from "react";
import { UserContext } from '../../UserContext.js';

export default function ModalSolicitud({asunto, enviar, isOpen, onClose, expe , showToast}){
    const { userData, setUserData } = useContext(UserContext);
    const [mensaje, setMensaje] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                cod_expe: expe,
                user: userData.DNI,
                nom_user: `${userData.NOMBRE} ${userData.APE_PAT} ${userData.APE_MAT}`,
                asunto: asunto,
                para: enviar,
                mensaje: mensaje
            };
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/enviar-solicitud', {
                method: 'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify(formData)                
            });
            const result = await response.json();
            if (result.status === true) {
                showToast('add_solicitud');
                onClose();
            } else {
                showToast('error');
                
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    if (!isOpen) return null;
    return (
        <div className="modal-overlay">
            <div className="modal-content lg:w-[50%] smm:w-[80%]">
                <div className='px-2 border-b-4 border-primary-800'>
                    <h2 className='mb-2 lg:text-xl font-semibold text-primary-800 smm:text-[12px]'>Solicitud para {expe}  </h2>
                </div>
                <form className="w-full mx-auto" onSubmit={handleSubmit}>
                    <div className='grid lg:grid-cols-3 lg:gap-4 mt-4 smm:grid-cols-1'>
                        <div>
                            <label htmlFor="user" className="block mb-2 smm:mb-0 lg:text-sm smm:text-[10px] font-medium text-primary-900">DNI USUARIO</label>
                            <input type="number" value={userData.DNI} id="DNI"  className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="DNI" disabled />
                        </div>
                        <div className="col-span-2 lg:mt-0 smm:mt-2">
                            <label htmlFor="razon_usuario" className="block mb-2 smm:mb-0 lg:text-sm smm:text-[10px] font-medium text-primary-900">DATOS DEL USUARIO</label>
                            <input type="text" id="RAZON" value={`${userData.NOMBRE} ${userData.APE_PAT} ${userData.APE_MAT}`}  className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Apellido Paterno" disabled />
                        </div>
                    </div>
                    <div className='grid lg:grid-cols-3 lg:gap-4 mt-4 smm:grid-cols-1'>
                        <div>
                            <label htmlFor="ASUNTO" className="block mb-2 smm:mb-0 lg:text-sm smm:text-[10px] font-medium text-primary-900">ASUNTO</label>
                            <input type="text" value={`SOLICITO ${asunto}`} id="ASUNTO"  className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Nombre" disabled />
                        </div>
                        <div className="col-span-2 lg:mt-0 smm:mt-2">
                            <label htmlFor="ASUNTO" className="block mb-2 smm:mb-0 lg:text-sm smm:text-[10px] font-medium text-primary-900">DIRIGIDO A: </label>
                            <input type="text" value={enviar} id="ASUNTO"  className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Nombre" disabled />
                        </div>
                    </div>
                    <div className='grid grid-cols-1 my-4'>  
                        <label htmlFor="message" className="block mb-2 smm:mb-0 lg:text-sm smm:text-[10px] font-medium text-primary-800 ">ESCRIBA EL MENSAJE</label>
                        <textarea placeholder="Escribe aquí un mensaje" id="message" rows="4" className="mt-1 block w-full px-3 py-2 text-primary-800 bg-primary-50 border border-primary-800 rounded-md text-sm shadow-sm placeholder-primary-400
                            focus:outline-none focus:border-primary-800 focus:ring-1 focus:ring-primary-800
                            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                            invalid:border-pink-500 invalid:text-pink-600
                            focus:invalid:border-pink-500 focus:invalid:ring-pink-500" 
                            value={mensaje}
                            onChange={(e) => setMensaje(e.target.value)}>
                        </textarea>
                    </div>
                    
                    <div className='flex justify-end p-2 border-t-2 border-primary-800 gap-4'>
                    <button type="submit" className='bg-primary-700 text-primary-50 rounded border-2 border-primary-800 p-2.5 hover:bg-primary-50 hover:text-primary-800'>Procesar</button>
                    <button type="button" onClick={onClose} className='bg-red-500 text-primary-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500'>Cerrar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}