import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import Navbar from "../navbar/navBar";
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import CardExpe_Carpeta_User from "../common/card/CardExpe_Carpeta_User.jsx";
import Loader from "../common/Loader.jsx";

export default function RevisarExpedienteAgencia(){
    const { agencia } = useParams();
    const [Analistas,setAnalistas]=useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchAgencias = async () => {
        try {
            const analistaResponse = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/get-user-por-agencia',{
                method:'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify({agencia:agencia})  
            });
            const analistaData = await analistaResponse.json();
            setAnalistas(analistaData.data);
        }catch(error){
            console.error('Error fetching options:', error);
        }finally{
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchAgencias();
    }, []);
    return(
        <>
        {isLoading && <Loader />}
        <Navbar/>
        <SidebarExpedientes/>
        {!isLoading && (<>
            <div className="p-4 sm:ml-64">
                <h1 className="font-light text-primary-800 m-2 text-xl smm:text-[12px]"><Link to="/expedientes/revisar_expediente">{"Revisar Expedientes >"}</Link><span className="font-bold">{agencia}</span></h1>
                <div className="p-4 border-2 grid grid-cols-1 gap-4 border-primary-800 border-dashed rounded-lg">
                <h1 className="text-primary-800 font-bold">ASESORES ACTIVOS</h1>
                    <div className="inline-flex justify-between mb-4">
                        <div className="w-full lg:grid lg:grid-cols-4 lg:gap-2 smm:grid-cols-1 smm:justify-center smm:items-center">
                        {Analistas && Analistas.map((analista) => (
                                (analista.estado=='ACTIVO'?
                                    <CardExpe_Carpeta_User
                                    key={analista.user}
                                    status={analista.estado}
                                    date={analista.fec_re}
                                    icons={'/icons/icons_profile_user.svg'}
                                    link={`/expedientes/revisar_expediente/${agencia}/${analista.user}`}
                                    title={analista.asesor}
                                />:
                                '')
                            ))}   
                        </div>
                    </div>
                </div>
                <div className="mt-2 p-4 border-2 grid grid-cols-1 gap-4 border-red-300 border-dashed rounded-lg">
                    <h1 className="text-red-500 font-bold">ASESORES RETIRADOS</h1>
                    <div className="inline-flex justify-between mb-4">
                        <div className="w-full lg:grid lg:grid-cols-4 lg:gap-2 smm:grid-cols-1 smm:justify-center smm:items-center">
                        {Analistas && Analistas.map((analista) => (
                                (analista.estado=='ACTIVO'?
                                    '':
                                    <CardExpe_Carpeta_User
                                    key={analista.user}
                                    status={analista.estado}
                                    date={analista.fec_re}
                                    icons={'/icons/icons_profile_user.svg'}
                                    link={`/expedientes/revisar_expediente/${agencia}/${analista.user}`}
                                    title={analista.asesor}
                                />)
                            ))}   
                        </div>
                    </div>
                </div>
            </div>
            </>)}    
        </>
    );
}