import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../navbar/navBar";
import Sidebar from "../sideBar/sideBar";
import Modal from "../common/Modal/ModalAddUser";

export default function Usuarios_Administrativos() {
    const [users, setUser] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpenAd, setIsModalOpenAd] = useState(false);
    const [selectedUserAd, setSelectedUserAd] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10;
    const [toastMessage, setToastMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-sql/api/user-administrativos', {
                    headers: {
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                if (!result.data || !Array.isArray(result.data)) {
                    throw new Error('Invalid data format received');
                }
                setUser(result.data);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleAddUserAd = (user) => {
        setSelectedUserAd(user);
        setIsModalOpenAd(true);
    };

    const handleCloseModal = () => {
        setIsModalOpenAd(false);
        setSelectedUserAd(null);
    };

    const showToast = (message) => {
        setToastMessage(message);
        if (message === 'existe') {
            toast.error('EL USUARIO EXISTE EN LA BASE DE DATOS');
        } else {
            toast.success('USUARIO AGREGADO EXITOSAMENTE');
        }
    };

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="p-4 sm:ml-64">
                <h1 className="font-bold text-primary-800 m-2 text-xl">USUARIOS ÁDMINISTRATIVO</h1>
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg">
                    {loading ? (
                        <div className="text-center">
                            <div role="status">
                                <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin fill-blue-800" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                                                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-2 py-3">APELLIDOS Y NOMBRE</th>
                                        <th scope="col" className="px-6 py-3">DNI</th>
                                        <th scope="col" className="px-6 py-3">AGENCIA</th>
                                        <th scope="col" className="px-6 py-3">USER SICOOP</th>
                                        <th scope="col" className="px-6 py-3">ESTADO</th>
                                        <th scope="col" className="px-6 py-3">OPCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentUsers.map((user) => (
                                        <tr key={user.DNI} className="bg-white border-b hover:bg-gray-50">
                                            <td className="w-4 p-4">
                                                <div className="flex items-center">
                                                    <input id={`checkbox-table-search-${user.DNI}`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                                                    <label htmlFor={`checkbox-table-search-${user.DNI}`} className="sr-only">checkbox</label>
                                                </div>
                                            </td>
                                            <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap">{user.RAZON}</th>
                                            <td className="px-6 py-4">{user.DNI}</td>
                                            <td className="px-6 py-4">{user.NOM_AGENCIA}</td>
                                            <td className="px-6 py-4">{user.USER}</td>
                                            <td className="px-6 py-4">{user.ESTADO==1?<div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div> Activo</div>:<div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div> DESACTIVADO</div>}</td>
                                            <td className="px-6 py-4">
                                            <div className="grid grid-cols-4 gap-2">
                                                <button onClick={() => handleAddUserAd(user)}>
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C3.79086 0 2 1.79086 2 4C2 6.20914 3.79086 8 6 8C8.20914 8 10 6.20914 10 4C10 1.79086 8.20914 0 6 0ZM4 9C1.79086 9 0 10.7909 0 13V14C0 15.1046 0.895431 16 2 16H10C11.1046 16 12 15.1046 12 14V13C12 10.7909 10.2091 9 8 9H4ZM12 8C12 7.44772 12.4477 7 13 7H14V6C14 5.44772 14.4477 5 15 5C15.5523 5 16 5.44772 16 6V7H17C17.5523 7 18 7.44772 18 8C18 8.55229 17.5523 9 17 9H16V10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10V9H13C12.4477 9 12 8.55229 12 8Z" fill="#02518c"/>
                                                    </svg>
                                                </button>
                                                <button>
                                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.895431 16 2 16H18C19.1046 16 20 15.1046 20 14V2C20 0.89543 19.1046 0 18 0H2ZM12 5C12 4.44772 12.4477 4 13 4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H13C12.4477 6 12 5.55228 12 5ZM12 8C12 7.44772 12.4477 7 13 7H16C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9H13C12.4477 9 12 8.55228 12 8ZM12 11C12 10.4477 12.4477 10 13 10H16C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12H13C12.4477 12 12 11.5523 12 11ZM4.00001 6C4.00001 4.34315 5.34315 3 7.00001 3C8.65686 3 10 4.34315 10 6C10 7.65685 8.65686 9 7.00001 9C5.34315 9 4.00001 7.65685 4.00001 6ZM5.94153 10C4.65023 10 3.50382 10.8263 3.09548 12.0513L3.05132 12.1838L3.04724 12.196C3.00516 12.3222 2.99152 12.3631 3.00501 12.3908C3.01149 12.4041 3.02423 12.4143 3.04307 12.4295C3.07467 12.4549 3.12346 12.4941 3.18877 12.5847C3.37672 12.8455 3.67856 13 4.00001 13H10C10.3215 13 10.6233 12.8455 10.8112 12.5847C10.8766 12.4941 10.9253 12.4549 10.9569 12.4295C10.9758 12.4143 10.9885 12.4041 10.995 12.3908C11.0085 12.3631 10.9949 12.3222 10.9528 12.1961L10.9528 12.196L10.9487 12.1838L10.9045 12.0513C10.4962 10.8263 9.34978 10 8.05849 10H5.94153Z" fill="#E79F10"/>
                                                    </svg>
                                                </button>
                                            </div>

                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {selectedUserAd && (
                            <Modal cod={'admi'} isOpen={isModalOpenAd} onClose={handleCloseModal} user={selectedUserAd} showToast={showToast} />
                            )}
                            <ToastContainer />
                            <div className="flex justify-between items-center p-4">
                                <span className="text-sm font-normal text-primary-800">Mostrando <span className="font-bold text-primary-800">{indexOfFirstUser + 1}-{indexOfLastUser}</span> de <span className="font-bold text-primary-800">{users.length}</span></span>
                                <nav className="flex items-center space-x-2">
                                    {Array.from({ length: Math.ceil(users.length / usersPerPage) }, (_, index) => (
                                        <button key={index + 1} onClick={() => paginate(index + 1)} className={`px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-primary-800 text-white' : 'bg-gray-200'}`}>
                                            {index + 1}
                                        </button>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Modal isOpen={isModalOpenAd} onClose={handleCloseModal} user={selectedUserAd} showToast={showToast} />
            <ToastContainer />
        </>
    );
}
